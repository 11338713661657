import { Grid, Typography } from '@mui/material'
import React from 'react'
import '../../../ArticlePage.css'

const WhatIsChatbot = () => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                Rule-based chatbots and conversational AI may sound similar, but they are quite different in how they operate. Rule-based chatbots function using pre-set scripts and simple "if-then" logic. They’re great for handling basic, repetitive tasks like answering FAQs or providing order updates. However, their main limitation is that they can’t go beyond their programming. If you ask a rule-based chatbot something unexpected or more complex, you’ll likely get a generic “I don’t understand” response.

                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                On the other hand, conversational AI takes chatbot technology to the next level. Using artificial intelligence (AI), natural language processing (NLP), and machine learning (ML), conversational AI chatbots understand not just the words you use but also the context and intent behind them. This makes them capable of having more meaningful, human-like conversations. Conversational AI can adapt to various queries, solve more complex issues, and continuously improve from interactions, making it a far more dynamic tool for customer service.
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                Now, let’s begin by setting the stage with a few definitions, and then we’ll dive into the fascinating
                                world of chatbots and conversational AI. Together, we’ll explore the similarities and differences that make
                                each of them unique in their own way.
                            </Typography>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default WhatIsChatbot