import React, { useState } from 'react';
import { Grid, Button, Typography, Dialog } from '@mui/material';
import ChromeIcon from "../../assests/Chrome.svg"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "./ChatbotGradient.css";
import BookAMeetingDialog from '../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog';
import BookACalendlyDialog from '../../Utils/GlobalDialog/BookACalendlyDialog/BookACalendlyDialog';

const ChatbotGradient = () => {

  const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);


  return (
    <>
      {flagOpenBookaMeetingDemo &&
        <Dialog open={flagOpenBookaMeetingDemo}
          maxWidth='lg'
          sx={{ '&.MuiPaper-root': { width: '100%' } }}
          className="iframe-dialog"
        >
          {/* <BookAMeetingDialog
            flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
            setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
          /> */}
          <BookACalendlyDialog
            flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
            setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
          />
        </Dialog>
      }

      <Grid container className='cb-gradiant-container'>
        <Grid item xl={6} lg={8.5} md={10.5} sm={11} xs={11} className='cb-gradiant-main-item'>
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', margin: "0px auto 20px" }}>
              <Typography component={'span'} className='cb-gradiant-head-text'>
                Get customized chatbot solutions to Engage, Retain & Grow
              </Typography>
            </Grid>

            {/* <Grid item xl={10} lg={10} md={11} sm={12} xs={12} sx={{ margin: '20px auto 0px' }}>
            <Grid container sx={{ justifyContent: 'space-between', gap: '10px', alignItems: 'center' }}>
              <Grid item xs={5.8}>
                <Grid container sx={{ justifyContent: 'end' }} >
                  <Grid item xs={7}>
                    <Button
                      fullWidth
                      size='large'
                      variant='contained'
                      className='chatbot-demno-btn'
                      onClick={() => window.open(`https://writewiz.ai/contact-sales`, '_blank')}
                    >
                      Schedule a Demo
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5.8}>
                <Grid container>
                  <Grid item xs={7}>
                    <Button
                      fullWidth
                      size='large'
                      variant='outlined'
                      className='chatbot-try-btn'
                      endIcon={<ArrowForwardIcon style={{ color: 'white' }} />}
                      onClick={() => window.open(`${process.env.REACT_APP_WEB_APP_REDIRECT_URL}/dashboard/chatbot/viewChatbot?chatbot_id=162`, '_blank')}
                    >
                      Try for Free
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}

            <Grid item xl={10} lg={10} md={11} sm={12} xs={12} sx={{ margin: '20px auto 0px' }}>
              <Grid container className='chatbot-grd-btn-container' >
                <Button
                  fullWidth
                  size='large'
                  variant='contained'
                  className='chatbot-demno-btn'
                  onClick={() => setFlagOpenBookaMeetingDemo(true)}
                >
                  Schedule a Demo
                </Button>

                <Button
                  fullWidth
                  size='large'
                  variant='outlined'
                  className='chatbot-try-btn'
                  endIcon={<ArrowForwardIcon style={{ color: 'white' }} />}
                  onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}/dashboard/chatbot/viewChatbot?chatbot_id=162`, '_blank')}
                >
                  Try for Free
                </Button>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ChatbotGradient