import { Grid, Typography } from '@mui/material'
import React from 'react'
import '../../../ArticlePage.css'

const FutureChatbot = () => {

    // const useCaseData = [
    //     {
    //         mainHead: "Chatbot vs. Conversational AI – Which is best for your business?",
    //         mainPoints: [
    //             {
    //                 content: "To say that chatbots and conversational AI are two different concepts would be wrong because they’re very interrelated and serve similar purposes. While chatbots are computer programs that automate text- and voice-based communication and customer service and serve as virtual support agents, conversational AI is the technology that fuels the creation of these human-like customer service virtual agents.",
    //             },
    //             {
    //                 content: "Due to this, many businesses are adopting the conversational AI approach to create an interactive, human-like customer experience. A recent study suggested that due to COVID-19, the adoption rate of automation and conversational interfaces went up to 52%, indicating that many companies are embracing this technology. This percentage is estimated to increase in the near future, pioneering a new way for companies to engage with their customers.",
    //             },
    //             {
    //                 content: "If you have a complex use case, conversational AI is the best way to go. Sometimes, people think for simpler use cases going with traditional bots can be a wise choice. However, the truth is, traditional bots work on outdated technology and have many limitations. Even for something as seemingly simple as an FAQ bot, can often be a daunting and time-consuming task.",
    //                 content2: "Organizations have historically faced challenges such as lengthy development cycles, extensive coding, and the need for manual training to create functional bots. However, with the advent of cutting-edge conversational AI solutions like Yellow.ai, these hurdles are now a thing of the past.",
    //                 content3: "The best part is that it uses the power of Generative AI to ensure that the conversations flow smoothly and are handled intelligently, all without the need for any training.",
    //             },
    //         ]
    //     },
    // ]

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                The future for rule-based chatbots is likely to remain focused on handling simple, repetitive tasks like answering FAQs or automating basic customer service processes. However, as technology advances and customer expectations rise, rule-based chatbots might face limitations. Their inability to grasp context and handle complex conversations will likely keep them relegated to straightforward interactions.

                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                Conversational AI, on the other hand, has a much brighter future. As AI, NLP, and ML continue to evolve, conversational AI will dominate the chatbot landscape. These chatbots are becoming more intelligent, understanding user preferences, context, and even emotional tone. Businesses will increasingly rely on conversational AI to provide more personalized, seamless, and efficient customer experiences. They will help anticipate customer needs, deliver proactive support, and play a central role in automating more sophisticated tasks across multiple platforms.
                            </Typography>
                        </Grid>

                        {/* {useCaseData.map((ele,) => {
                            return (
                                <>
                                    <Grid item xs={12} className='arp-paragraph-global-sub-head-text-item' >
                                        <Typography
                                            component='span'
                                            className='arp-paragraph-global-sub-head-text'
                                        >
                                            {ele.mainHead}
                                        </Typography>
                                    </Grid>
                                    {ele.mainPoints.map((data, index) => {
                                        const useCaseIdx = index + 1
                                        return (
                                            <>
                                                <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                                                    <Grid container>
                                                        {data.title &&
                                                            <Grid item xs={12} className='arp-paragraph-global-text-item'>
                                                                <Typography
                                                                    component='span'
                                                                    className='arp-paragraph-global-head-text'
                                                                >
                                                                    {useCaseIdx}. {data.title}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                        {data.content &&
                                                            <Grid item xs={12} className='arp-paragraph-global-head-text-item'>
                                                                <Typography
                                                                    component='span'
                                                                    className='artical-paragraph-global-text'
                                                                >
                                                                    {data.content}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                        {data.content2 &&
                                                            <Grid item xs={12} className='arp-paragraph-global-head-text-item'>
                                                                <Typography
                                                                    component='span'
                                                                    className='artical-paragraph-global-text'
                                                                >
                                                                    {data.content2}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                        {data.content3 &&
                                                            <Grid item xs={12} className='arp-paragraph-global-head-text-item'>
                                                                <Typography
                                                                    component='span'
                                                                    className='artical-paragraph-global-text'
                                                                >
                                                                    {data.content3}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                    })

                                    }
                                </>
                            )


                        })} */}

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default FutureChatbot