import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import EastIcon from '@mui/icons-material/East';
import './InstagramHeadSection.css'
import instagramHeroSection from '../../assests/Solution/Instagram/instagramHeroImage.svg'
import { useNavigate } from 'react-router-dom';


const InstagramHeadSection = (props) => {

    const { flagBanner } = props
    const navigate = useNavigate()

    return (
        <>
            <Grid container>
                <Grid item xl={9} lg={10.5} md={10} sm={10} xs={11} className={flagBanner ? 'in-head-sec-main-item' : 'in-head-sec-main-item2'}>
                    <Grid container className='in-head-sec-main-container'>
                        <Grid item xl={5.5} lg={5.8} md={12} sm={12} xs={12}>
                            <Grid container>
                                <Grid item xs={12} className='in-head-sec-label-item'>
                                    <Typography variant='span' className='in-head-sec-label'>
                                        INSTAGRAM
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className='in-head-sec-text-item'>
                                    <Typography variant='span' className='in-head-sec-text'>
                                        Elevate Instagram Engagement with AI-Powered Conversations
                                    </Typography>
                                </Grid>
                                <Grid item xl={10} lg={9} md={10} sm={11} xs={12} className='in-automate-text-item'>
                                    <Typography variant='span' className='in-automate-text'>
                                        Supercharge your Instagram experience by using cutting-edge AI-driven chat flows to engage with customers and boost conversions seamlessly. With Chronox AI, your Instagram interactions become more personalized, effective, and automated.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className='in-btn-item'>
                                    <Grid container className='in-btn-container'>
                                        <Grid item >
                                            <Button
                                                variant='contained'
                                                size='large'
                                                className='get-started-free-btn'
                                                onClick={() => { window.open(`${process.env.REACT_APP_REDIRECT_URL}`, `_blank`) }}
                                            >
                                                Get started free
                                            </Button>
                                        </Grid>
                                        <Grid item >
                                            <Button
                                                variant='text'
                                                className='in-content-btn'
                                                size='large'
                                                endIcon={<EastIcon style={{ color: "rgba(106, 9, 125, 1)" }} />}
                                                onClick={() => { navigate('/contact-us') }}
                                            >
                                                Contact Sales
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={5.5} lg={5.8} md={12} sm={12} xs={12} className='insta-head-img-item'>
                            <img src={instagramHeroSection} alt="" className='insta-head-img' />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default InstagramHeadSection