import { Grid, Typography } from '@mui/material'
import React from 'react'
import '../../../ArticlePage.css'

const UseCases = () => {

    const useCaseData = [
        {
            mainHead: "Rule-Based Chatbots:",
            mainPoints: [
                {
                    title: 'Basic FAQs',
                    content: "Rule-based chatbots excel at answering simple, commonly asked questions like “What are your business hours?” or “What is your return policy?” These tasks don’t require complex responses and are easily handled by pre-programmed scripts. It’s quick, efficient, and provides immediate answers to customers, reducing wait times for basic information."
                },
                {
                    title: 'Appointment scheduling',
                    content: "These chatbots streamline the process of booking appointments by guiding users step-by-step through available time slots and confirming appointments. Whether it's scheduling a doctor’s visit, reserving a table at a restaurant, or booking a salon appointment, rule-based chatbots help users easily complete the process without human intervention."
                },
                {
                    title: 'Order Tracking',
                    content: "Rule-based chatbots are great for handling routine customer inquiries like order status updates. Customers can quickly ask the chatbot for real-time tracking information or estimated delivery times, removing the need to manually search for tracking numbers or call customer service."
                },
                {
                    title: 'Password Resets and Account Help:',
                    content: "Automating simple support tasks like resetting passwords, updating personal information, or checking account balances is another ideal use case. Rule-based chatbots guide users through these routine processes, freeing up human agents to focus on more complicated issues."
                },
            ]
        },
        {
            mainHead: "Conversational AI Chatbots:",
            mainPoints: [
                {
                    title: 'Personalized Product Recommendations:',
                    content: "Conversational AI chatbots analyze customer behavior and preferences, allowing them to suggest tailored product recommendations. For example, in an e-commerce setting, the chatbot might recommend products based on past purchases or browsing history. This personalized approach makes the shopping experience more engaging and relevant for the customer, driving higher satisfaction and potential sales."
                },
                {
                    title: 'Resolving Complex Problems:',
                    content: "Unlike rule-based bots, conversational AI is capable of understanding and addressing more complicated issues. If a customer is experiencing a technical problem or has a detailed query, the chatbot can intelligently diagnose the problem and offer step-by-step solutions. This reduces the need for human intervention in situations that would typically require a support agent."
                },
                {
                    title: 'Understanding Natural Language:',
                    content: "Conversational AI chatbots have the ability to understand the way people naturally talk. Whether customers phrase questions differently, use slang, or misspell words, the chatbot can still grasp the intent of the question and provide an appropriate response. This adaptability ensures a smoother, more user-friendly interaction, especially compared to the rigid nature of rule-based bots."
                },
                {
                    title: 'Handling Multi-Step Processes:',
                    content: "Conversational AI chatbots are skilled at managing multi-step, more intricate tasks that require several interactions. For example, they can guide users through troubleshooting a technical issue, filling out a form, or making complex bookings. The chatbot keeps track of the conversation, allowing it to address follow-up questions or changes to the process without losing context, providing a smooth and efficient experience for the user."
                },
            ]
        },
    ]

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        {/* <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                The goal of chatbots and conversational AI is to enhance the customer service experience. Chatbot responds with predefined answers based on programmed rules.
                                However, conversational AI offers a more advanced and dynamic approach, enabling more natural, personalized, and intelligent conversations with customers,
                                and has proven to offer significantly improved CX and reduced costs over traditional chatbots.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                Let’s dive into some real-life scenarios where chatbots and conversational AI shine in customer service:
                            </Typography>
                        </Grid> */}

                        {useCaseData.map((ele,) => {
                            return (
                                <>
                                    <Grid item xs={12} className='arp-paragraph-global-sub-head-text-item' >
                                        <Typography
                                            component='span'
                                            className='arp-paragraph-global-sub-head-text'
                                        >
                                            {ele.mainHead}
                                        </Typography>
                                    </Grid>
                                    {ele.mainPoints.map((data, index) => {
                                        const useCaseIdx = index + 1
                                        return (
                                            <>
                                                <Grid item xs={12} className='arp-paragraph-global-head-text-item'>
                                                    <Grid container>
                                                        <Grid item xs={12} className='arp-paragraph-global-text-item'>
                                                            <Typography
                                                                component='span'
                                                                className='arp-paragraph-global-head-text'
                                                            >
                                                                {useCaseIdx}. {data.title}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <Typography
                                                                component='span'
                                                                className='artical-paragraph-global-text'
                                                            >
                                                                {data.content}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                    })

                                    }
                                </>
                            )


                        })}

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default UseCases