import { Grid, Typography } from '@mui/material'
import React from 'react'


const NextStep = () => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                The next big leap in chatbot technology came with the introduction of natural language processing (NLP). NLP allowed chatbots to better understand human language, not just as a series of keywords but as full sentences with context. This advancement meant that chatbots could interpret a wider variety of queries and respond more naturally, offering a more human-like experience.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                NLP-powered chatbots could handle inquiries phrased in different ways and didn’t rely on exact keyword matches. For example, instead of responding only to "What is your return policy?" the bot could also understand questions like "Can I return something?" or "How do returns work?" This development improved the overall user experience, making interactions feel less robotic.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default NextStep