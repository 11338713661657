import { Button, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import React, { useLayoutEffect, useRef } from 'react'
import GitexLogo from './../../assests/GlobalImages/gitex-white-logo.svg'
import './BannerComponent.css'
import CloseIcon from '@mui/icons-material/Close';
import verifiedIcon from './../../assests/LandingPage/whiteMetaVerified.png'
import ChronoxAI from './../../assests/GlobalImages/chronox-white-new-logo.svg'
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const BannerComponent = (props) => {
    const { setFlagBanner, bannerRef } = props;
    const smallScreen = useMediaQuery('(max-width : 900px) and (min-width : 0px)')
    const isBelowLargeScreen = useMediaQuery('(max-width : 1535px) and (min-width : 0px)')
    const navigate = useNavigate()

    return (
        <Grid container sx={{ position: "relative" }}>
            <Grid item className='banner-background-style' ref={bannerRef} >
                <Grid container direction={smallScreen ? "column" : "row"} justifyContent={"space-around"} alignItems={"center"} flexWrap={"nowrap"} gap={1}>

                    {!smallScreen &&
                        <Grid item xl={2} lg={2} md={1.5} xs={2}>
                            <Grid container flexWrap={"nowrap"} alignItems={"center"} justifyContent={"space-between"} gap={1}>
                                <Grid item>
                                    <img src={GitexLogo} alt="" className='' style={{ width: '100%', height: '100%' }} />
                                </Grid>
                                {smallScreen &&
                                    <Grid item>
                                        <img src={verifiedIcon} alt="" style={{ maxHeight: "45px" }} />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    }


                    <Grid item xl={10.5} lg={10} md={12} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xl={8} lg={9} md={9} sm={12} xs={12} className='crnx-middle-item'>
                                <Grid container direction={"column"} alignItems={"center"} gap={1}>
                                    {!smallScreen &&
                                        <Grid item textAlign={"center"}>
                                            <span className='join-us-text-style'>Meet us at the Largest Tech & Startup Event in the World</span>
                                        </Grid>
                                    }
                                    {!smallScreen &&
                                        <Grid item>
                                            <Grid container direction={"row"} gap={2} flexWrap={"nowrap"}>
                                                <Grid item className='hall-number-style' >
                                                    <span className='number-heading-style'>14 Oct 2024 - 18 Oct 2024</span>
                                                </Grid>
                                                <Grid item className='hall-number-style'>
                                                    <span className='number-heading-style'>Dubai, UAE</span>
                                                </Grid>
                                                <Grid item>
                                                    <span className='number-heading-style'>Stand H7-19</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {smallScreen &&
                                        <Grid item>
                                            <Grid container direction={"row"} gap={2} flexWrap={"nowrap"}>

                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Grid container sx={{ gap: '10px', alignItems: 'center' }}>
                                                                <Grid item>
                                                                    <img src={GitexLogo} alt="" className='b-gitex-logo' />
                                                                </Grid>
                                                                <Divider style={{ borderWidth: '1px', borderColor: 'white !important', margin: '0px 2px', borderColor: "#FFFFFF" }} orientation="vertical" variant="middle" flexItem />
                                                                <Grid item sx={{ display: "flex" }}>
                                                                    <img src={ChronoxAI} alt="Chronox AI" srcset="" className='b-chronox-ai-logo' />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                            <Typography
                                                                variant='span'
                                                                className='join-us-text-style'
                                                            >
                                                                Meet us at the Largest Tech & Startup Event in the World
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ marginTop: "5px" }}>
                                                            <Grid container direction={"row"} gap={2} flexWrap={"nowrap"}>
                                                                <Grid item className='hall-number-style'>
                                                                    <span className='number-heading-style'>14 Oct 2024 - 18 Oct 2024</span>
                                                                </Grid>
                                                                <Grid item className='hall-number-style'>
                                                                    <span className='number-heading-style'>Dubai, UAE</span>
                                                                </Grid>
                                                                <Grid item>
                                                                    <span className='number-heading-style'>Stand H7-19</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* <Grid item className='hall-number-style'>
                                                    <span className='number-heading-style'>GITEX</span>
                                                    <span className='number-heading-style' style={{ whiteSpace: "pre" }}>GLOBAL 2024</span>
                                                </Grid>
                                                <Grid item className='hall-number-style'>
                                                    <span className='number-heading-style'>Oct</span>
                                                    <span className='number-heading-style' style={{ whiteSpace: "pre" }}>14 - 18</span>
                                                </Grid>
                                                <Grid item className='hall-number-style'>
                                                    <span className='number-heading-style'>Dubai</span>
                                                    <span className='number-heading-style' style={{ whiteSpace: "pre" }}>UAE</span>
                                                </Grid>
                                                <Grid item>
                                                    <span className='number-heading-style'>Stand No.</span>
                                                    <span className='number-heading-style' style={{ whiteSpace: "pre" }}>H7-19 Hall 7</span>
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>

                            {!smallScreen &&
                                <Grid item xl={4} lg={3} md={3} xs={3}>
                                    <Grid container sx={{ alignItems: "center", justifyContent: 'center', height: '100%', gap: '35px' }}>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                size='large'
                                                className='book-meet-btn'
                                                onClick={() => { navigate('/contact-us') }}
                                            >
                                                Book a Booth Meetup
                                            </Button>
                                        </Grid>
                                        {isBelowLargeScreen ? <></> :
                                            <Grid item sx={{ display: "flex" }}>
                                                <img src={ChronoxAI} alt="Chronox AI" srcset="" className='b-chronox-ai-logo' />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>


                    <Grid item className='close-banner-style'>
                        <CloseIcon sx={{ color: "white", cursor: "pointer" }} onClick={() => setFlagBanner(false)} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default BannerComponent
