import { Grid, Typography } from '@mui/material'
import React from 'react'


const Innovations = () => {

    const innovation = [
        {
            head: "Natural Language Processing (NLP):",
            subhead: " NLP was a major milestone that helped chatbots understand and interpret human language, making conversations feel more natural."
        },
        {
            head: "Machine Learning (ML):",
            subhead: "Machine learning allowed chatbots to learn from previous interactions, improving over time without manual updates or reprogramming."
        },
        {
            head: "Voice Integration:",
            subhead: "As virtual assistants like Siri, Alexa, and Google Assistant became popular, chatbots started to integrate voice capabilities, enabling users to interact hands-free and providing another layer of conversational convenience."
        },
        {
            head: "Multichannel Support:",
            subhead: "Today’s chatbots can operate across multiple platforms—social media, websites, messaging apps—allowing businesses to reach customers wherever they are. This integration ensures consistency in responses and a smooth user experience across channels."
        },
        {
            head: "Personalization:",
            subhead: "Conversational AI allows chatbots to offer personalized recommendations and tailored responses based on user data and past interactions. This creates a more engaging and customized experience for users."
        },
    ]

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        {innovation.map((ele, index) => {
                            const iDx = index + 1
                            return (
                                <>
                                    <Grid item xs={12} sx={{ margin: "15px 0px" }}>
                                        <Typography
                                            component='span'
                                            sx={{ fontSize: "15px", fontWeight: '600', color: 'black' }}
                                        >
                                            {iDx}.&nbsp;{ele.head}&nbsp;
                                            <Typography component={'span'} className='artical-paragraph-global-text'>
                                                {ele.subhead}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                </>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default Innovations