import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import '../../../ArticlePage.css'
import CircleIcon from '@mui/icons-material/Circle';

const Ecommerce = () => {

    const useCaseData = [
        {
            title: 'E-Commerce: Personalized Shopping Assistance.',
            content: "TE-commerce businesses thrive on personalized customer experiences, and conversational AI chatbots are perfect for delivering this. By analyzing user preferences and browsing history, these bots can recommend products, answer questions about orders, and even handle complex returns.",
            ussagePoints: [
                'Assistance with order tracking, cancellations, or returns',
                'Providing real-time answers to customer inquiries about pricing, discounts, and stock availability',
            ]
        },
    ]

    return (
        <>
            {useCaseData.map((ele, index) => {
                const useCaseIdx = index + 1
                return (
                    <>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Grid container>
                                <Grid item xs={12} >
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Grid container>
                                                <Grid item xs={12} className='arp-paragraph-global-text-item'>
                                                    <Typography
                                                        variant='span'
                                                        sx={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                                                    >
                                                        Comman uses:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} className='arp-paragraph-global-text-item'>
                                                    {ele?.ussagePoints?.map((points) => {
                                                        return (
                                                            <>
                                                                <Grid container sx={{ marginLeft: '20px' }}>
                                                                    <Grid item xs={12}>
                                                                        <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                                            <Grid item sx={{ maxWidth: '15px' }}>
                                                                                <CircleIcon style={{ width: '10px', height: 'auto' }} fontSize="small" />
                                                                            </Grid>
                                                                            <Grid item sx={{ maxWidth: "calc(100% - 20px)", width: "calc(100% - 15px)" }}>
                                                                                <Typography
                                                                                    variant='span'
                                                                                    sx={{ fontSize: '14px', fontWeight: '500', color: 'black' }}
                                                                                >
                                                                                    {points}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                            </>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )
            })}
        </>
    )
}

export default Ecommerce