import { Grid, Typography } from '@mui/material'
import React from 'react'


const RiseOfConversationalAI = () => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                The most significant breakthrough came with conversational AI, which combined the power of artificial intelligence, machine learning, and advanced NLP. Unlike their predecessors, conversational AI chatbots don’t just follow rules—they learn from user interactions, adapting over time to become more effective.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                These intelligent chatbots can understand the context of a conversation, detect user intent, and even offer personalized responses. For instance, if a customer asks about a specific product they’ve previously purchased, the chatbot can recall that information and offer tailored recommendations. Conversational AI systems like these can handle complex, multi-turn conversations and provide a seamless, natural flow of communication.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                The rise of machine learning (ML) also allowed these chatbots to continuously improve without human intervention. They learn from past conversations and adjust their responses accordingly, becoming smarter and more efficient over time.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default RiseOfConversationalAI