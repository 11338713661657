import { Box, Grid, useMediaQuery, Typography, Dialog } from "@mui/material";
import './FacebookChatBotGlobal.css'
import IntroSectionImg from "../../assests/FacebookChatbot/facebook-intro-img.png"
import { Link } from "react-router-dom";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import BookAMeetingDialog from "../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog";
import BookACalendlyDialog from "../../Utils/GlobalDialog/BookACalendlyDialog/BookACalendlyDialog";
gsap.registerPlugin(SplitText)
gsap.registerEffect({
    name: "fade",
    effect: (targets, config) => {
        return gsap.fromTo(targets,
            { duration: config.duration, opacity: 0, scale: 0.3, },
            { duration: config.duration, opacity: 1, scale: 1 }
        );
    },
    defaults: { duration: 1 },
    extendTimeline: true,
});

const FacebookIntroSection = (props) => {

    const { flagBanner } = props
    const isBelow900 = useMediaQuery('(min-width: 0px) and (max-width: 900px)')
    const [linkHoverPostion, setLinkHoverPostion] = useState(false)
    const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false)
    const headingBoxRef = useRef(null)
    const introImgRef = useRef(null)

    useLayoutEffect(() => {
        const timeLine = gsap.timeline()
        timeLine.fade(introImgRef.current)

        const splitText = Array.from(headingBoxRef.current.children)
        splitText.forEach((element, ind) => {
            const textChars = new SplitText(element, { type: "chars, words" });
            gsap.fromTo(
                textChars.chars,
                { opacity: 0, y: 20 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    delay: ind * 0.5,
                    ease: 'power3.out',
                    stagger: 0.05,
                }
            );
        })
        return () => {
            timeLine.kill()
            splitText.forEach((element) => {
                const textChars = new SplitText(element, { type: "chars, words" });
                textChars.revert();
            });
        };
    }, [])

    return (
        <>
            {
                flagOpenBookaMeetingDemo &&
                <Dialog open={flagOpenBookaMeetingDemo}
                    maxWidth='lg'
                    sx={{ '&.MuiPaper-root': { width: '100%' } }}
                    className="iframe-dialog"
                >
                    {/* <BookAMeetingDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    /> */}
                    <BookACalendlyDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    />
                </Dialog>
            }
            <Grid container className="fbis-main-container">
                <Grid container className={flagBanner ? "outer-wrapper" : flagBanner && isBelow900 ? "outer-wrapper" : "outer-wrapper-without-banner"}>
                    <Box className='fbis-left-content-box'>
                        <Box ref={headingBoxRef}>
                            <Typography className="main-heading" variant="h1">
                                Better Engagements:
                            </Typography>
                            <Typography className="main-heading highlighted-text" variant="h1">
                                Conversational AI for Facebook
                            </Typography>
                        </Box>
                        <Typography variant="body1" className="para-content">
                            The best chatbot for Facebook will help you interact with over 2 billion customers on the world’s most popular social media platform. Chatbot for Facebook Messenger makes customer reach more accessible and helps you scale your customer base!
                        </Typography>
                        <Box sx={{ marginTop: "45px" }}>
                            <Link className='fbis-schedule-demo-btn' onClick={() => { setFlagOpenBookaMeetingDemo(true) }}>
                                Schedule a Demo
                            </Link>
                        </Box>
                    </Box>
                    <Box className='fbis-right-content-box'>
                        <img ref={introImgRef} src={IntroSectionImg} alt="facebook-intro-img.png" className="fbis-intro-img" />
                    </Box>
                </Grid>
            </Grid >
        </>
    )
}
export default FacebookIntroSection;