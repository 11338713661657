import React, { useState } from 'react'
import './MobileDrawer.css'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, List, ListItem, Typography } from '@mui/material'
import ChronoxPrimaryLogo from "../../../assests/LandingPage/ChronoxNewLogo.svg"
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MobileDrawer = (props) => {

    const { handleDrawerToggle } = props

    const navigate = useNavigate();


    const handleLinkClick = (url) => {
        navigate(url);
    };

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const menuItems = [
        {
            title: 'Platform',
            links: [
                {
                    name: 'AI Chatbot',
                    url: '/ai-chatbot'
                },
                {
                    name: 'WhatsApp Chatbot',
                    url: '/whatsapp-ai-chatbot'
                },

                {
                    name: 'Omnichannel Deployment',
                    url: '/omnichannel-deployment'

                },
                {
                    name: 'Interactive Messages',
                    url: '/interactive-message'
                },
                {
                    name: 'Integration',
                    url: '/'
                },
                {
                    name: 'RAG & AI Actions',
                    url: '/'
                },
                {
                    name: 'Live Agent Assist',
                    url: '/'
                },
                {
                    name: 'Call Analysis',
                    url: '/'
                },
                {
                    name: 'Voice AI',
                    url: '/'
                },
            ],
        },
        {
            title: 'Solution',
            links: [
                {
                    name: 'Website',
                    url: '/website-ai-chatbot'
                },
                {
                    name: 'Facebook',
                    url: '/facebook-ai-chatbot'
                },
                {
                    name: 'WhatsApp',
                    url: '/whatsapp-ai-chatbot'
                },
                {
                    name: 'Instagram',
                    url: '/instagram'
                },
                {
                    name: 'Slack',
                    url: '/slack-ai-chatbot'
                },
                {
                    name: 'Email',
                    url: '/'
                },
            ],
        },
        {
            title: 'Industries',
            links: [
                {
                    name: 'Healthcare',
                    url: "/industry/healthcare"
                },
                {
                    name: 'Food Sector',
                    url: "/industry/food"
                },
                {
                    name: 'Retail',
                    url: "/industry/retail"
                },
                {
                    name: 'E-Commerce',
                    url: "/industry/ecommerce"
                },
                {
                    name: 'Blogs',
                    url: "/blog"
                },
            ],
        },
        {
            title: 'Pricing',
            links: [
                {
                    name: 'Chatbot Pricing',
                    url: '/chatbot-pricing'
                },
            ],
        },
        {
            title: 'Contact',
            links: [
                {
                    name: 'Contact Us',
                    url: '/contact-us'
                },
            ],
        },
        {
            title: 'Gitex',
            links: [
                {
                    name: 'Gitex',
                    url: '/chronox-at-gitex-global-dubai'
                },
            ],
        },
    ];

    return (
        <>
            <Grid container className="nav-drawer-main-container" >
                <Grid item xs={12} className="nav-drawer-main-item">
                    <Grid container className="nav-drawer-main-wrapper">
                        <Grid item xs={12} className='nav-drawer-head-item'>
                            <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                <Grid item xs={8}>
                                    <img
                                        src={ChronoxPrimaryLogo}
                                        alt="Chronox"
                                        width={"auto"}
                                        marginTop={'10px !important'}
                                        style={{ cursor: "pointer" }}
                                        height={"auto"}
                                        className="chronox-logo"
                                        onClick={() => navigate('/')}
                                    />
                                </Grid>
                                <Grid item xs={2} sx={{ textAlign: 'end' }}>
                                    <IconButton sx={{ padding: '0px', verticalAlign: 'baseline' }}>
                                        <CloseIcon
                                            sx={{ color: 'black' }}
                                            onClick={() => { handleDrawerToggle() }}
                                        />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className="nav-drawer-menu-main-item">
                            <Grid container>
                                <Grid item xs={12} >
                                    <Grid container>
                                        <Grid item xs={12} className='nav-drawer-item'>
                                            {menuItems.map((menu, index) => (
                                                <Accordion
                                                    key={index}
                                                    className='nav-menu-accordian'
                                                    sx={{
                                                        borderBottom: expanded === index ? '1px solid #6A097D' : '1px solid #d0d0d0'
                                                    }}

                                                    expanded={expanded === index} // Move expanded to Accordion
                                                    onChange={handleChange(index)} // Move onChange to Accordion
                                                >
                                                    <AccordionSummary
                                                        disableScrollLock={true}
                                                        expandIcon={<ExpandMoreIcon style={{ color: 'black', fontSize: '20px' }} />}
                                                        aria-controls={`panel${index}-content`}
                                                        id={`panel${index}-header`}
                                                        className='nav-menu-accordian-summery'
                                                    >
                                                        <Typography variant='span' className='nav-accordian-head-text'>
                                                            {menu.title}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails className='nav-menu-accordian-details'>
                                                        <Grid container>
                                                            {menu.links.map((link, linkIndex) => (
                                                                <Grid item xs={12} className='nav-accordian-link-item'>
                                                                    <Typography
                                                                        key={linkIndex}
                                                                        variant='span'
                                                                        onClick={() => handleLinkClick(link.url)}
                                                                        className='nav-accordian-menu-text'
                                                                        sx={{ color: '#6A097D' }}
                                                                    >
                                                                        {link.name}
                                                                    </Typography>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                    <Grid container sx={{ justifyContent: 'space-between' }}>
                                        <Grid item xs={6}>
                                            <Grid container>
                                                <Grid item xs={12} sx={{ margin: '3px 0px' }}>
                                                    <Typography variant="span"
                                                        sx={{ color: "#6A097D", fontSize: "14px", fontWeight: "500", cursor: "pointer" }}
                                                        onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}/privacy-policy`, `_blank`)}>
                                                        Privacy Policy
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ margin: '3px 0px' }}>
                                                    <Typography
                                                        sx={{ color: "#6A097D", fontSize: "14px", fontWeight: "500", cursor: "pointer" }}
                                                        variant="span"
                                                        onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}/terms`, `_blank`)}>
                                                        T&C
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} sx={{ maxWidth: "100px", textAlign: 'end' }}>
                                            <Button
                                                fullWidth
                                                variant='outlined'
                                                size='small'
                                                className='nav-login-btn'
                                                onClick={() => {
                                                    window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_self')
                                                    handleDrawerToggle()
                                                }}
                                            >
                                                Login
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* <Grid item xs={12} sx={{ margin: '15px 0px', maxWidth: '100px' }}>
                                    <Button
                                        fullWidth
                                        variant='outlined'
                                        size='small'
                                        className='nav-login-btn'
                                        onClick={() => {
                                            window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_self')
                                            handleDrawerToggle()
                                        }}
                                    >
                                        Login
                                    </Button>
                                </Grid> */}
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>


        </>
    )
}

export default MobileDrawer