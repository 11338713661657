import { Grid } from '@mui/material'
import React from 'react'
import Navbar from '../Navbar/Navbar'
import AiWhatsappHeadSection from '../../Component/AiWhatsappHeadSection/AiWhatsappHeadSection'
import './../Chatbot/Chatbot.css'
import AiWhatsappScrollingSection from '../../Component/AiWhatsappScrollingSection/AiWhatsappScrollingSection'


const AiWhatsappLanding = () => {
    return (
        <>
            <Navbar />
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} className='brsw-new-head-section-main-item'>
                            <AiWhatsappHeadSection />
                        </Grid>
                        <Grid item xl={9} lg={9} md={9} sm={11} xs={11} sx={{ margin: '40px auto' }}>
                            <AiWhatsappScrollingSection />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default AiWhatsappLanding