import { Grid, Typography } from '@mui/material'
import React from 'react'


const EarlyDaysRuleBasedChatbot = () => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                In the beginning, chatbots were relatively simple. These rule-based chatbots operated using a series of predefined rules and “if-then” logic. They were designed to handle basic tasks like answering frequently asked questions (FAQs) or providing order updates. While they could assist with specific queries, their functionality was limited. These bots would follow a strict decision tree, and if a user asked something outside their programmed knowledge, they couldn’t respond effectively.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                For example, early chatbots in customer service could answer questions like “What are your business hours?” or “How can I reset my password?” However, they lacked flexibility and could not handle more complex queries, often leading to user frustration. Rule-based chatbots were efficient for automating repetitive tasks, but they didn’t offer much in terms of real conversation.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default EarlyDaysRuleBasedChatbot