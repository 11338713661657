import { Button, Dialog, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import './AiWhatsappHeadSection.css'
import metaImage from '../../assests/LandingPage/meta-provider.svg'
import headImg from '../../assests/AiWhatsapp/Head-Img-section.svg'
import BookAMeetingDialog from '../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog'
import BookACalendlyDialog from '../../Utils/GlobalDialog/BookACalendlyDialog/BookACalendlyDialog'


const AiWhatsappHeadSection = () => {

    const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);
    {
        flagOpenBookaMeetingDemo && (
            <Dialog
                open={flagOpenBookaMeetingDemo}
                maxWidth='lg'
                sx={{ '&.MuiPaper-root': { width: '100%' } }}
                className="iframe-dialog"
            >
                {/* <BookAMeetingDialog
                    flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                    setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                /> */}
                <BookACalendlyDialog
                    flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                    setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                />
            </Dialog>
        )
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} className='aiw-main-section-item'>
                    <Grid container>
                        <Grid item xl={10} lg={10.5} md={10} xs={10.5} className='aiw-head-section-item'>
                            <Grid container sx={{ justifyContent: 'space-between' }}>
                                <Grid item xl={5.8} lg={5.8} md={12} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <img
                                                src={metaImage}
                                                className='aiw-meta-img'
                                                alt="Meta Verified"
                                                srcset=""
                                            />
                                        </Grid>
                                        <Grid item xs={11} sx={{ margin: '10px 0px' }}>
                                            <Typography
                                                variant='span'
                                                className='aiw-head-text'
                                            >
                                                Engage & Sell on WhatsApp like never before
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                            <Typography
                                                variant='span'
                                                className='aiw-sub-head-text'
                                            >
                                                Engage your customers where they are - on WhatsApp, trusted by over 2 billion users for everyday communication.
                                                From handling inquiries to driving sales, your business scales effortlessly while delivering a personalized touch.
                                                No complicated workflows, just powerful automation that works for your business 24/7.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '30px 0px' }}>
                                            <Grid container sx={{ gap: '15px' }}>
                                                <Grid item>
                                                    <Button
                                                        variant='contained'
                                                        size='large'
                                                        fullWidth
                                                        className='aiw-gen-ai-btn'
                                                    >
                                                        Build your own Gen-AI Chatbot


                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant='contained'
                                                        size='large'
                                                        fullWidth
                                                        className='aiw-book-demo-btn'
                                                        onClick={() => {
                                                            setFlagOpenBookaMeetingDemo(true)
                                                        }}
                                                    >
                                                        Book a Demo
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xl={5.8} lg={5.5} md={12} sm={12} xs={12} sx={{ textAlign: 'end' }}>
                                    <img
                                        src={headImg}
                                        alt=""
                                        srcset=""
                                        className='aiw-right-img'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AiWhatsappHeadSection