import React from 'react'
import { Grid, Typography } from '@mui/material'
import '../../../ArticlePage.css'


const WhatIsConversationalChatbot = () => {
    return (
        <>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} className='arp-blog-content-spacing'>
                        <Typography
                            component='span'
                            className='artical-paragraph-global-text'
                        >
                            A conversational AI chatbot is much more advanced. It uses AI, natural language processing (NLP), and machine learning (ML) to understand and respond in a way that feels more like a human conversation. Rather than relying on rigid scripts, conversational AI learns from past interactions and can handle more complex, dynamic queries. For example, if a customer asks for personalized recommendations or has a technical issue, a conversational AI chatbot can understand the context, analyze the problem, and provide a solution. These chatbots improve over time, continuously learning from the data they process, which allows them to offer more personalized and effective customer support.
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12} className='arp-blog-content-spacing'>
                        <Typography
                            component='span'
                            className='artical-paragraph-global-text'
                        >
                            According to an MIT Technology Review report, more than 90% of businesses reported that they had seen significant improvements in complaint resolution,
                            call processing, and customer and employee satisfaction with conversational AI chatbots. Conversational AI is enabling businesses
                            to deliver more personal experiences to their users by having more fluid and intelligent conversations. Artificial intelligence, with its capabilities
                            of comprehending natural language,
                            active learning, dialogue flow management, and data mining, helps transform and automate end-to-end user journeys
                        </Typography>
                    </Grid> */}
                </Grid>
            </Grid>
        </>
    )
}

export default WhatIsConversationalChatbot