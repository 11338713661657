import React, { useState } from 'react'
import './AccordianImageSelection.css'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
// import ChatbotComponentDesign from '../ChatbotCustomerDesignSection/ChatbotComponentDesign'
// import demoImage from './../../assests/LandingPage/chatbotProfileAvtar.png'
// import IconsColor from '../../Utils/Global/iconsColor'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordianImageSelection = (props) => {
    const { content } = props;
    const [selectServiceData, setSelectServiceData] = useState(content.serviceData[0])

    const [expanded, setExpanded] = useState(content.serviceData[0].serviceId);
    const [selectedImage, setSelectedImage] = useState(content.serviceData[0].serviceImage); // Initial image state

    const handleChange = (panel, image) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        if (isExpanded) {
            setSelectedImage(image); // Update image based on accordion selection
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xl={9} lg={11} md={9} sm={9} xs={11} className='service-head-text-main-item'>
                    <Grid container direction={"column"}>
                        <Grid item xs={11} className='service-head-text-item'>
                            <Typography
                                variant='span'
                                className='service-head-normal-text-color'
                                sx={{ fontWeight: '600' }}
                            >
                                {content.heading}
                            </Typography>
                        </Grid>

                        <Grid item xs={10} sm={9} sx={{ width: { xs: "90%", sm: "70%" } }} className='service-subhead-text-item'>
                            <Typography variant='span' className='service-head-sub-normal-text-color'>
                                {content.subHeading}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} mt={{ xs: 5, lg: 11 }} className='service-body-main-item'>
                            <Grid container gap={4}>
                                {/* Accordion Section */}
                                <Grid item xs={12} lg={6.5}>
                                    {content.serviceData.map((data) => (
                                        <Accordion
                                            key={data.serviceId}
                                            expanded={expanded === data.serviceId}
                                            onChange={handleChange(data.serviceId, data.serviceImage)} // Pass image to handler
                                            className={expanded === data.serviceId ? 'selected-service-data' : 'select-service-data'}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`panel${data.serviceId}-content`}
                                                id={`panel${data.serviceId}-header`}
                                            >
                                                <Typography
                                                    variant="span"
                                                    className={expanded === data.serviceId ? 'selected-service-heading-name' : 'service-heading-name'}
                                                >
                                                    {data.headingDate}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography
                                                    variant='span'
                                                    className={`service-heading-sub-name ${expanded === data.serviceId ? 'visible' : 'hidden'}`}
                                                >
                                                    {data.subHeadingData}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Grid>

                                {/* Image Section */}
                                <Grid item xs={12} lg={4.5} xl={4} alignSelf={"center"} className='chatbot-component-position-style'>
                                    <img src={selectedImage} alt='service' className='chatbot-int-img' />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AccordianImageSelection
