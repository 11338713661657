import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import { Grid } from '@mui/material'
import ContactSalesForm from '../../Component/ContactSalesForm/ContactSalesForm'
import LandingFooter from '../../Component/Footer/LandingFooter'
import '../../Component/ContactSalesForm/ContactSalesForm.css'
import ContactSalesOverlapFooter from '../../Component/ContactSalesOverLapFooter/ContactSalesOverlapFooter'
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter'


const ContactSales = (props) => {
    const { flagBanner, setFlagBanner } = props
    const [scrollEffect, setScrollEffect] = useState(false);



    useEffect(() => {

        const changeStructure = () => {
            if (window.scrollY > 90) {
                setScrollEffect(true);
            } else {
                setScrollEffect(false);
            }
        };

        changeStructure();
        window.addEventListener("scroll", changeStructure);

        return () => {
            window.removeEventListener("scroll", changeStructure);
        };
    }, []);

    return (
        <>
            <Grid>
                <Navbar flagBanner={flagBanner} setFlagBanner={setFlagBanner} scrollEffect={scrollEffect} setScrollEffect={setScrollEffect} />
                <Grid container mt={flagBanner ? 9 : 0}>
                    <Grid item xs={12} className='csf-main'>
                        <Grid container>
                            <Grid item xs={12}>
                                <ContactSalesForm />
                            </Grid>
                            <Grid item xs={12} style={{ position: "relative", marginTop: "170px" }}>
                                {/* <ContactSalesOverlapFooter /> */}
                                <ParaphraseOverlapFooter />
                            </Grid>
                            <Grid item xs={12}>
                                <LandingFooter />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ContactSales