import React from 'react'
// import LazyLoad from 'react-lazyload'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function LazyLoading(props) {

    return (

        <>
            {/* <LazyLoad height={150} offset={100} once>
                {props.flagShow && <img src={props.src} className={props.className} style={props.style} />}
            </LazyLoad> */}
            {props.flagShow &&
                <React.Fragment className={props.className}>
                    <LazyLoadImage
                        src={props.src}
                        style={props.style}
                        wrapperProps={{
                            style: { transitionDelay: "5s ease-in" },
                        }}
                        alt={props.alt}
                    />
                </React.Fragment>
            }
        </>
    )

}

