import React, { useState } from 'react'
import './WhatsappChatbot.css'
import "../Chatbot/Chatbot.css";
import '../../Pages/Paraphraser/Paraphraser.css'
import { Grid, useMediaQuery } from '@mui/material'
import Navbar from '../Navbar/Navbar'
import ChatbotWhatsppInfo from '../../Component/ChatbotWhatsppInfo/ChatbotWhatsppInfo'
import ChatbotWhatsappHeadSection from '../../Component/ChatbotWhatsappHeadSection/ChatbotWhatsappHeadSection'
import ChatbotWhatsappGridSection from '../../Component/ChatbotWhatsappGridSection/ChatbotWhatsappGridSection'
import ChatbotWhatsappTryitSection from '../../Component/ChatbotWhatsappTryitSection/ChatbotWhatsappTryitSection'
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs';
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter';
import LandingFooter from '../../Component/Footer/LandingFooter';
import ChatbotWhatsappCustomSection from '../../Component/ChatbotWhatsappCustomSection/ChatbotWhatsappCustomSection';
import ChatbotWhatsappCustomizeSection from '../../Component/ChatbotWhatsappCustomizeSection/ChatbotWhatsappCustomizeSection';
import ChatbotWhatsappBusinessSection from '../../Component/ChatbotWhatsappBusinessSection/ChatbotWhatsappBusinessSection';
import ChatbotWhatsappCustomAISection from '../../Component/ChatbotWhatsappCustomAISection/ChatbotWhatsappCustomAISection';
import firstImage from '../../assests/ChatbotWhatsapp/cw-chat-first-card-1.svg'
import secondImage from '../../assests/ChatbotWhatsapp/cw-chat-second-card-1.svg'
import thirdImage from '../../assests/ChatbotWhatsapp/cw-chat-third-card-1.svg'
import messanger from '../../assests/messenger.svg'

const WhatsappChatbot = (props) => {
    const { flagBanner, setFlagBanner } = props;
    const [pageName, setPageName] = useState('Chatbot');
    const isScreenWidthBelow900 = useMediaQuery('(max-width : 900px) and (min-width : 0px)')
    const groupSectionData = [
        {
            mainHeading: "Instantly sync your Data Flow across your systems",
            subheading1: "Connect in real-time with APIs, CRM, and databases to ensure data is always current and accurate.",
            subheading2: "Enable smarter decisions with data-driven insights directly within your WhatsApp conversations.",
            subheading3: "Reduce response times and increase efficiency with automated data retrieval and updates.",
            leftSideimage: false,
            image: firstImage,
            altText: 'Connect with all platforms and data-driven insights',
            points: [
                { label: 'Real-time connectivity', img: messanger },
                { label: 'Data-driven insights', img: messanger },
                { label: 'Smart WhatsApp decision', img: messanger },
            ]
        },
        {
            mainHeading: "Easy toggle between AI and Human Interaction",
            subheading1: "Toggle between AI-driven and manual conversations seamlessly to best suit the customer’s needs.",
            subheading2: "Use AI-assisted prompts to enhance human interactions, ensuring no detail is missed.",
            subheading3: "Maintain control with options to intervene manually when nuanced conversations are required.",
            leftSideimage: isScreenWidthBelow900 ? false : true,
            image: secondImage,
            altText: 'Easy interaction with AI and human entities',
            points: [
                { label: 'Seamless Toggles', img: messanger },
                { label: 'AI-assisted prompts', img: messanger },
                { label: 'Ensure detail accuracy', img: messanger },
            ]
        },
        {
            mainHeading: "Automate your Tasks & Messaging using API",
            subheading1: "Deploy automatic WhatsApp messages through our robust API, ensuring timely and consistent communication with users.",
            subheading2: "Customize message templates to reflect your brand’s tone and style, making automated interactions feel as personal as live chats.",
            subheading3: "Utilize automation to handle common inquiries and free up your team to focus on more complex customer needs, enhancing overall service efficiency.",
            leftSideimage: false,
            image: thirdImage,
            altText: 'Auto-generated message templates for your Tasks & Messaging',
            points: [
                { label: 'Automatic WhatsApp messages', img: messanger },
                { label: 'Brand-specific templates', img: messanger },
                { label: 'Personalized message templates', img: messanger },
            ]
        },

    ]

    return (
        <>
            <Navbar flagBanner={flagBanner} setFlagBanner={setFlagBanner} />

            <Grid container mt={flagBanner ? 9 : 2}>
                <Grid item xs={12}>

                    <Grid container>

                        {/* CHAT HEAD SECTION */}
                        <Grid item xs={12} className='cw-head-section-main-item'>
                            <ChatbotWhatsappHeadSection />
                        </Grid>

                        <Grid item xs={12} className='cw-bz-section-main-item'>
                            <ChatbotWhatsappBusinessSection />
                        </Grid>

                        {/* CHAT GRID SECTION */}
                        <Grid item xs={12} >
                            <ChatbotWhatsappGridSection groupSectionData={groupSectionData} />
                        </Grid>

                        {/* CHAT INFO SECTION */}
                        <Grid item xs={12} className='cw-info-section-main-item'>
                            <ChatbotWhatsppInfo />
                        </Grid>

                        {/* CHAT GRID SECTION */}
                        <Grid item xs={12} className='cw-tryit-main-item'>
                            <ChatbotWhatsappTryitSection />
                        </Grid>

                        <Grid item xs={12} className='cw-custom-main-item'>
                            <ChatbotWhatsappCustomAISection />
                        </Grid>

                        <Grid item xs={12} className='cw-custom-main-item'>
                            <ChatbotWhatsappCustomizeSection />
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: '180px' }}>
                            {/* <ChatbotFAQs /> */}
                        </Grid>

                        <Grid item xs={12} className='pr-overlap-item'>
                            <ParaphraseOverlapFooter pageName={pageName} />
                        </Grid>

                        <Grid item xs={12} className='brsw-footer-component-main-item'>
                            <LandingFooter />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default WhatsappChatbot