import React from 'react'
import { Grid } from "@mui/material";
import "./ChatbotStats.css";
import facebook from "../../assests/Chatbot-Facebook-Logo-Link.svg";
import Slack from "../../assests/slackNew.svg";
import Web from "../../assests/web.svg";
import Whatsapp from "../../assests/whatsappNew.svg";


const StatCard = ({ percentageVal, text }) => {
  return (
    <Grid container className="SC-Main-OuterMainContainer" direction={'column'}>
      <h2 className="SC-numberValue">{percentageVal}</h2>
      <p className="SC-textValue">{text}</p>
    </Grid>
  );
};


const ChatbotStats = () => {

  const SocialLinks = [
    { link: "https://api.whatsapp.com/send?phone=917861834273", icon: Whatsapp, class: "cbs-work-flow-icons" },
    { link: "https://www.example.com", icon: Slack, class: "cbs-work-flow-icons" },
    { link: "https://www.example.com", icon: Web, class: "cbs-work-flow-icons" },
    { link: "https://www.facebook.com/writewizai", icon: facebook, class: "cbs-work-flow-fb-icons" }];

  const sampleStatsInfo = [
    {
      percentageVal: "80%",
      text: 'Enhanced Customer Satisfaction'
    },
    {
      percentageVal: "30%",
      text: 'Reduced Response Time'
    },
    {
      percentageVal: "40%",
      text: 'Increased Lead Generation'
    },
    {
      percentageVal: "20%",
      text: 'Boosted Operational Efficiency'
    },]

  return (
    <Grid container className="CBS-mainOuterContainer">
      <Grid xl={10} lg={10} md={10} sm={10} xs={10.5} item>
        <Grid container className="CBS-mainInfoContainer">

          <Grid xl={4} lg={4.5} md={4.5} sm={11} xs={10}>
            <Grid container className='CBS-MainInfoTextContainer'>

              <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <p className='CBS-InfoTextMainText'>
                  Personalizing messages at scale
                  <span> across key messaging channels.
                  </span>
                </p>
              </Grid>

              <Grid xs={12} className='CBS-icon-main-item'>
                <Grid container className='CBS-InfoText-IconContainer'>
                  {SocialLinks.map((obj, index) => (
                    <Grid key={index} xs={1.5} className='CBS-InfoText-Icon'>
                      <img src={obj?.icon} className={obj.class} alt="" loading='lazy' />
                    </Grid>
                  ))}
                </Grid>
              </Grid>

            </Grid>
          </Grid>

          <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
            <Grid container className="CBS-stateCardContainer" columnGap={1} rowGap={3}>
              {sampleStatsInfo.map((stat, index) => (
                <Grid key={index} xs={5.8}>
                  <StatCard percentageVal={stat.percentageVal} text={stat.text} />
                </Grid>
              ))}
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}

export default ChatbotStats