import { Grid, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import Navbar from '../../Pages/Navbar/Navbar'
import "./../../Pages/Chatbot/Chatbot";
import './../../Pages/Paraphraser/Paraphraser.css'
import ChatbotHero from '../ChatbotHero/ChatbotHero';
import ChatbotExperience from '../ChatbotExperience/ChatbotExperience';
import ChatbotExploreMobile from '../ChatbotExplore/ChatbotExploreMobile';
import ChatbotExplore from '../ChatbotExplore/ChatbotExplore';
import ChatbotFeatures from '../ChatbotFeatures/ChatbotFeatures';
import ChatbotStats from '../ChatbotStats/ChatbotStats';
import ChatbotWorkFlow from '../ChatbotWorkFlow/ChatbotWorkFlow';
import ChatbotCardItems from '../ChatbotCardItems/ChatbotCardItems';
import ChatbotGradient from '../ChatbotGradient/ChatbotGradient';
import ParaphraseOverlapFooter from '../ParaphraserSections/ParaphraseOverlapFooter';
import LandingFooter from '../Footer/LandingFooter';
import AiChatbotMobileCarouselAnimation from './AiChatbotMobileCarouselAnimation';
import AiChatbotCarouselAnimation from './AiChatbotCarouselAnimation';

const AiChatbot = (props) => {
    const isBelow1200 = useMediaQuery('(min-width: 0px) and (max-width: 1200px)')
    const [pageName, setPageName] = useState('Chatbot');
    const { flagBanner, setFlagBanner } = props;

    return (
        <Grid container>
            <Navbar flagBanner={flagBanner} setFlagBanner={setFlagBanner} />
            <Grid item xs={12} mt={flagBanner ? 9 : 0}>
                <Grid container>
                    <Grid item xs={12} className='brsw-component-main-item'>
                        <ChatbotHero />
                    </Grid>

                    <Grid item xs={12} >
                        <ChatbotExperience />
                    </Grid>

                    {/* <Grid item xs={12} className='brsw-component-main-item'>
                        {isBelow1200 ? <ChatbotExploreMobile /> : <ChatbotExplore />}
                    </Grid> */}

                    <Grid item xs={12} className='brsw-component-main-item'>
                        {isBelow1200 ? <AiChatbotMobileCarouselAnimation /> : <AiChatbotCarouselAnimation />}
                    </Grid>

                    <Grid item xs={12} className='CB-chatBotFeatures'>
                        <ChatbotFeatures />
                    </Grid>

                    <Grid item xs={12} className='CB-chatBotStats'>
                        <ChatbotStats />
                    </Grid>

                    <Grid item xs={12} className='CB-chatBotStats'>
                        <ChatbotWorkFlow />
                    </Grid>

                    <Grid item xs={12} className='brsw-component-main-item'>
                        <ChatbotCardItems />
                    </Grid>

                    <Grid item xs={12} className='brsw-component-main-item'>
                        <ChatbotGradient />
                    </Grid>

                    <Grid item xs={12} className='pr-overlap-item'>
                        <ParaphraseOverlapFooter pageName={pageName} />
                    </Grid>

                    <Grid item xs={12} className='brsw-footer-component-main-item'>
                        <LandingFooter />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AiChatbot
