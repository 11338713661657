import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import '../../../ArticlePage.css'
import CircleIcon from '@mui/icons-material/Circle';


const Insurance = () => {

    const useCaseData = [
        {
            title: 'Insurance: Generating Quotes and Handling Inquiries',
            content: "In the insurance industry, conversational AI chatbots simplify the process of generating quotes and handling customer inquiries. These bots can guide users through the process of selecting the right insurance plan, answering questions about coverage options, and helping customers complete their applications.",
            ussagePoints: [
                'Generating personalized insurance quotes based on user inputs',
                'Answering questions about policy coverage, premiums, and deductibles',
                'Guiding customers through the process of applying for insurance or filing claims',
            ]
        },
    ]

    return (
        <>
            {useCaseData.map((ele, index) => {
                const useCaseIdx = index + 1
                return (
                    <>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Grid container>
                                <Grid item xs={12} >
                                    <Grid container>
                                        <Grid item xs={12} sx={{ mb: '30px' }}>
                                            <Typography
                                                component='span'
                                                className='artical-paragraph-global-text'
                                            >
                                                {ele.content}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Grid container>
                                                <Grid item xs={12} className='arp-paragraph-global-text-item'>
                                                    <Typography
                                                        variant='span'
                                                        sx={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                                                    >
                                                        Comman uses:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} className='arp-paragraph-global-text-item'>
                                                    {ele?.ussagePoints?.map((points) => {
                                                        return (
                                                            <>
                                                                <Grid container sx={{ marginLeft: '20px' }}>
                                                                    <Grid item xs={12}>
                                                                        <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                                            <Grid item sx={{ maxWidth: '15px' }}>
                                                                                <CircleIcon style={{ width: '10px', height: 'auto' }} fontSize="small" />
                                                                            </Grid>
                                                                            <Grid item sx={{ maxWidth: "calc(100% - 20px)", width: "calc(100% - 15px)" }}>
                                                                                <Typography
                                                                                    variant='span'
                                                                                    sx={{ fontSize: '14px', fontWeight: '500', color: 'black' }}
                                                                                >
                                                                                    {points}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                            </>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )
            })}
        </>
    )
}

export default Insurance