import { Grid } from '@mui/material'
import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import SideBySideSectionWithBtn from '../../Component/GlobalComponents/SideBySideSectionWithBtn/SideBySideSectionWithBtn'
// import img1 from '../../assests/slackHeroImage.png'
import './WebsiteAiChatbot.css'
import AccordianImageSelection from '../../Component/GlobalComponents/AccordianImageSelection/AccordianImageSelection'
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs'
import LandingFooter from '../../Component/Footer/LandingFooter'
import GlobalTab from '../../Component/GlobalTabChanges/GlobalTab'
import img1 from "../../assests/Platform/Interactive/tab-img1.png"
import img2 from "../../assests/Platform/Interactive/tab-img2.png"
import img3 from "../../assests/Platform/Interactive/tab-img3.png"
import img4 from "../../assests/Platform/Interactive/tab-img4.png"
import img5 from "../../assests/Platform/Interactive/tab-img5.png"

import image1 from "../../assests/Solution/webai/wai-img-1.1.png"
import image2 from "../../assests/Solution/webai/wai-img-1.png"
import image3 from "../../assests/Solution/webai/wai-img-2.png"
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter'




const WebsiteAiChatbot = (props) => {
    const { flagBanner, setFlagBanner } = props

    const heroSectionData = {
        heading: "Turn web visitors into loyal customers",
        description: "Your AI-powered website assistant works 24/7 to engage visitors, answer questions, and turn interest into action. Keep your audience engaged and your team focused on what matters most.",
        image: image1,
        btnText: "Get Started",
        btnGlobalCustomStyle: 'slack-hero-section-btn-style',
        btnVariant: 'contained',
        flagImageDirection: "right",
        textDirection: "left",
        headingGlobalCustomStyle: "website-hero-section-heading-style",
        descriptionGlobalCustomStyle: "website-hero-section-description-style",
        headingLabel: "WEBSITE CHATBOT",
        headingLabelCustomStyle: "website-heroSection-label-style",
        onClick: () => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')
    }

    const firstSidebysideSectionData = {
        heading: "Keep Visitors Engaged, Gather Insights Effortlessly",
        description: "The AI agent collects feedback, runs surveys, and engages visitors with personalized messages—gathering insights while delivering value in real-time. Keep your audience hooked and improve your messaging with every interaction.",
        image: image2,
        flagImageDirection: "left",
        textDirection: "left",
        headingGlobalCustomStyle: "website-hero-section-heading-style",
        descriptionGlobalCustomStyle: "website-hero-section-description-style",
        headingLabel: "MARKETING",
        headingLabelCustomStyle: "website-heroSection-label-style"
    }

    const secondSidebysideSectionData = {
        heading: "Engage, Qualify, and Close Deals—All Without Lifting a Finger.",
        description: "Automatically qualify leads, answer product inquiries, and guide prospects to the right offerings. With your AI agent, you never miss an opportunity—keeping the sales funnel flowing.",
        image: image3,
        flagImageDirection: "right",
        textDirection: "left",
        headingGlobalCustomStyle: "website-hero-section-heading-style",
        descriptionGlobalCustomStyle: "website-hero-section-description-style",
        headingLabel: "SALES",
        headingLabelCustomStyle: "website-heroSection-label-style"
    }


    const [chatbotFaqs, setChatbotFaqs] = useState([
        {
            question: "What makes this AI agent different from other chatbots?",
            answer: "Our AI agent isn’t just about conversations—it drives engagement with image carousels, quick replies, and actionable CTAs. It personalizes interactions, offers seamless human handoffs when needed, and integrates effortlessly with your existing tools, ensuring a smooth, efficient customer experience.",
        },
        {
            question: "How fast can I set it up on my website?",
            answer: "In just a few minutes! With our no-code, no workflow platform, you simply write what you need, and your chatbot is ready to go live. No complicated workflows or technical expertise required."
        },
        {
            question: "Can I customize the chatbot’s design and behavior?",
            answer: "Absolutely! From tone and personality to the chatbot’s visual design, you have complete control to match your brand perfectly. Embed it anywhere on your site—on product pages, forms, or checkout—so your customers get help where they need it most.",
        },
        {
            question: "Does the AI agent work with my existing systems?",
            answer: "Yes! Our AI agent integrates seamlessly with CRMs, helpdesk software, and other tools, ensuring your data flows smoothly and your team stays in sync."
        },
        {
            question: "What if the AI agent can’t answer a customer’s query?",
            answer:
                "No problem! When the AI reaches its limits, it hands over the conversation to a human agent smoothly—ensuring a seamless experience with zero disruption to the customer."
        },
        {
            question: "How does the chatbot help with engagement and conversions?",
            answer:
                "The AI agent interacts visually and dynamically—using image carousels, quick replies, and CTAs—to guide customers through product discovery, answer questions instantly, and drive meaningful actions like purchases or bookings."
        },
    ])


    const tabData = {
        mainHead: "Turn your conversations into revenue",
        tabButton: [
            {
                img: img1,
                headText: "Image Card Carousel",
                subText: "Appointment Booking",
                caption: `The Image Card Carousel feature helps businesses visually present multiple services or products, such as doctor availability or events, in a scrollable format. Each card includes key details like provider names and availability along with action buttons such as "Book an Appointment" or "Show Availability." This intuitive setup simplifies decision-making by allowing users to explore options quickly and take immediate action.`
            },
            {
                img: img2,
                headText: "Information Card",
                subText: "Quote Generation",
                caption: `The Image Card Carousel feature helps businesses visually present multiple services or products, such as doctor availability or events, in a scrollable format. Each card includes key details like provider names and availability along with action buttons such as "Book an Appointment" or "Show Availability." This intuitive setup simplifies decision-making by allowing users to explore options quickly and take immediate action.`
            },
            {
                img: img3,
                headText: "Suggestion Chips",
                subText: "Predefined categories",
                caption: `The Suggestion Chips feature saves users time by offering pre-set reply options, enabling faster and more intuitive interactions. These clickable suggestions streamline conversations by reducing the need for typing, making it easy for users to select relevant options with just a tap.In this example, the virtual assistant presents predefined categories such as cocktails, beer, whiskey, wine, and non-alcoholic drinks. This feature ensures smooth, guided interactions, improving user experience by delivering quick access to relevant information or actions.`
            },
            {
                img: img4,
                headText: "Call-to-Action Buttons",
                subText: "Event Registration",
                caption: `The Call-to-Action (CTA) Buttons feature boosts engagement by providing users with easy-to-click buttons that smoothly guide them to the next step in their journey. A common use case is event registration, where participants can register directly through interactive platforms. Each card displays an event image along with key details, accompanied by a "Register Now" button to prompt quick and seamless responses.`
            },
            {
                img: img5,
                headText: "Quick Replies",
                subText: "Surveys & Feedback",
                caption: `The Quick Replies feature enhances user interactions by providing predefined response options, making it faster and easier for users to engage. A great example of this feature is seen in surveys and feedback campaigns. Businesses can use bots to run targeted surveys, automate feedback collection, and capture valuable insights from customers. These quick reply buttons allow users to provide instant responses with minimal effort, improving response rates.`
            },
        ]
    }


    return (
        <>
            <Grid container>
                <Navbar setFlagBanner={setFlagBanner} flagBanner={flagBanner} />
                <Grid item xs={12} mt={flagBanner ? 7 : 0}>
                    <Grid item xs={12} className='website-side-by-side-hero-section' sx={{
                        background: "rgb(244, 236, 248)",
                        background: "linearGradient(0deg, rgba(244, 236, 248, 1) 11 %, rgba(236, 239, 248, 1) 24 %, rgba(237, 238, 248, 1) 47 %, rgba(251, 245, 233, 1) 100 %)"
                    }} >
                        <SideBySideSectionWithBtn content={heroSectionData} flagHeroSection />
                    </Grid>
                    <Grid item xs={12} className='website-side-by-side-hero-section'>
                        <SideBySideSectionWithBtn content={firstSidebysideSectionData} />
                    </Grid>
                    <Grid item xs={12} className='website-side-by-side-hero-section'>
                        <SideBySideSectionWithBtn content={secondSidebysideSectionData} />
                    </Grid>
                    <Grid item xs={12} className='website-side-by-side-hero-section' sx={{ margin: "80px 0px" }}>
                        <GlobalTab tabData={tabData} />
                    </Grid>
                    <Grid item xs={12} className='website-side-by-side-hero-section' sx={{ margin: '20px 0px 150px' }}>
                        <ChatbotFAQs faqData={chatbotFaqs} />
                    </Grid>
                    <Grid item xs={12} className='pr-overlap-item'>
                        <ParaphraseOverlapFooter pageName={"Chatbot"} />
                    </Grid>
                    <Grid item xs={12} className='website-side-by-side-hero-section'>
                        <LandingFooter />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default WebsiteAiChatbot
