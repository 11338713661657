import { useEffect, useRef, useState } from "react";
import './FacebookChatbot.css'
import Navbar from "../Navbar/Navbar";
import { Grid } from "@mui/material";
import FacebookIntroSection from "../../Component/FacebookChatbot/FacebookIntroSection";
import FacebookBotFeature from "../../Component/FacebookChatbot/FacebookBotFeatures";
import ChatbotFAQs from "../../Component/ChatbotFAQs/ChatbotFAQs";
import ParaphraseOverlapFooter from "../../Component/ParaphraserSections/ParaphraseOverlapFooter";
import LandingFooter from "../../Component/Footer/LandingFooter";
// import ScrollSmoother from "gsap/ScrollSmoother";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import gsap from "gsap";
import { useLocation } from "react-router-dom";
import AIFacebookEngagement from "../../Component/AIFacebookEngagement/AIFacebookEngagement";
import ChatbotWhatsappCustomizeSection from "../../Component/ChatbotWhatsappCustomizeSection/ChatbotWhatsappCustomizeSection";
import AIChatbotSimplified from "../../Component/AIChatbotSimplified/AIChatbotSimplified";
gsap.registerPlugin(ScrollTrigger, ScrollSmoother, useGSAP);

const FacebookChatbot = (props) => {
    const { flagBanner, setFlagBanner } = props;
    const containerRef = useRef(null)
    const [pageName, setPageName] = useState('Chatbot');

    const [chatbotFaqs, setChatbotFaqs] = useState([
        {
            question: "Is Chronox Messenger integration free?",
            answer: "A WhatsApp Chatbot uses AI and predefined responses to interact with users, answer queries, and perform tasks, providing a seamless experience.",
        },
        {
            question: "Why do i need Facebook Messanger integration",
            answer: "WhatsApp Chatbots improve efficiency, offer 24/7 support, and personalize interactions, leading to enhanced customer satisfaction and business growth."
        },
        {
            question: "What is a Messenger Flow?",
            answer: "WhatsApp Chatbots prioritize security, ensuring data protection and compliance with WhatsApp's policies.",
        },
        {
            question: "What can a Facebook Messanger Flow do for me?",
            answer: "You can ask questions related to our services, product details, pricing, account management, support queries, and more. Our chatbot is designed to provide you with quick information and assist you in navigating our website."
        },
    ])

    // useEffect(() => {
    //     const smoother = ScrollSmoother.create({
    //         content: containerRef.current,
    //         smooth: 1,
    //         effects: true,
    //         smoothTouch: 0.1,
    //     });
    //     return () => smoother.kill()
    // }, []);

    return (
        <>

            {/* <Grid ref={containerRef} container className="fbc-main-container">
                <Grid item xs={12}>
                    <FacebookIntroSection />
                </Grid>


                <Grid item xs={12} sx={{ margin: '100px 0px 100px' }}>
                    <AIFacebookEngagement />
                </Grid>

                <Grid item xs={12}>
                    <FacebookBotFeature />
                </Grid>
                </Grid> */}



            <Navbar flagBanner={flagBanner} setFlagBanner={setFlagBanner} />
            <Grid ref={containerRef} container className={flagBanner ? "fbc-main-container" : "fbc-main-container-without-banner"}>
                <Grid item xs={12} ><FacebookIntroSection flagBanner={flagBanner} /></Grid>
                <Grid item xs={12}><FacebookBotFeature /></Grid>

                <Grid item xs={12}>
                    <ChatbotFAQs faqData={chatbotFaqs} />
                </Grid>

                <Grid item xs={12} sx={{ margin: '50px 0px 200px' }}>
                    <ChatbotWhatsappCustomizeSection />
                </Grid>

                {/* <Grid item xs={12} className='faceboor-pr-overlap-item'>
                    <ParaphraseOverlapFooter pageName={pageName} />
                </Grid>

                <Grid item xs={12} className='brsw-footer-component-main-item'>
                    <LandingFooter />
                </Grid> */}

                <Grid item xs={12} className='pr-overlap-item'>
                    <ParaphraseOverlapFooter pageName={pageName} />
                </Grid>

                <Grid item xs={12} className='brsw-footer-component-main-item'>
                    <LandingFooter />
                </Grid>
            </Grid>
        </>
    )
}

export default FacebookChatbot;