import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import SideBySideSectionWithBtn from '../../Component/GlobalComponents/SideBySideSectionWithBtn/SideBySideSectionWithBtn'
import { Grid, useMediaQuery } from '@mui/material'
import './SlackAiChatbot.css'
import ClientLogoSection from '../../Component/GlobalComponents/ClientLogoSection/ClientLogoSection'
import ChatbotWhatsappGridSection from '../../Component/ChatbotWhatsappGridSection/ChatbotWhatsappGridSection'
import firstImage from '../../assests/Solution/Slack/slack-slider-img.png'
import secondImage from '../../assests/ChatbotWhatsapp/cw-chat-second-card-1.svg'
import thirdImage from '../../assests/Solution/Slack/slack-db-img.png'
import messanger from '../../assests/messenger.svg'
import AiWhatsappScrollingSection from '../../Component/AiWhatsappScrollingSection/AiWhatsappScrollingSection'
import GlobalCTABanner from '../../Component/GlobalComponents/GlobalCTABanner/GlobalCTABanner'
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs'
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter'
import LandingFooter from '../../Component/Footer/LandingFooter'
import EastIcon from '@mui/icons-material/East';
import slackHeroImage from '../../assests/Solution/Slack/slack-head-img.png'
import AIChatbotSimplified from '../../Component/AIChatbotSimplified/AIChatbotSimplified'


const SlackAiChatbot = (props) => {
    const { flagBanner, setFlagBanner } = props
    const isScreenWidthBelow900 = useMediaQuery('(max-width : 900px) and (min-width : 0px)')

    const heroSectionData = {
        heading: "Supercharge Slack with AI-Powered Conversations",
        description: "Transform your Slack channels into dynamic, AI-driven hubs for communication and task automation. Chronox AI helps you streamline workflows, automate repetitive processes, and foster better team collaboration through intelligent chat interactions.",
        image: slackHeroImage,
        btnText: "Get Started",
        btnGlobalCustomStyle: 'slack-hero-section-btn-style',
        btnVariant: 'contained',
        flagImageDirection: "right",
        textDirection: "center",
        headingGlobalCustomStyle: "slack-hero-section-heading-style",
        descriptionGlobalCustomStyle: "slack-hero-section-description-style",
        flagLogoImageSrc: "https://cdn-icons-png.flaticon.com/512/2111/2111615.png",
        onClick: () => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')
    }

    const groupSectionData = [
        {
            mainHeading: "Automate Repetitive Tasks with AI",
            subheading1: "Chronox AI automates routine tasks within Slack, such as setting reminders, scheduling meetings, and generating reports.",
            subheading2: "The conversational AI chatbot handles tasks efficiently, allowing your team to focus on more important work.",
            subheading3: "The chatbot responds naturally to requests, ensuring seamless task completion and improved workflow.",
            leftSideimage: false,
            image: firstImage,
            altText: 'Connect with all platform and data-driven insights',
            points: [
                { label: 'Real-time connectivity', img: messanger },
                { label: 'Data-driven insights', img: messanger },
                { label: 'Smart WhatsApp decision', img: messanger },
            ]
        },
        {
            mainHeading: "Boost Team Engagement with AI-Powered Collaboration",
            subheading1: "Chronox AI improves team communication by delivering real-time updates, notifications, and task tracking.",
            subheading2: "The AI bot engages directly with the team, providing relevant information and reminders through natural dialogue.",
            subheading3: "This automation keeps everyone aligned without the need for manual intervention.",
            leftSideimage: isScreenWidthBelow900 ? false : true,
            image: secondImage,
            altText: 'Easy interaction with AI and human entities',
            points: [
                { label: 'Seamless Toggles', img: messanger },
                { label: 'AI-assisted prompts', img: messanger },
                { label: 'Ensure detail accuracy', img: messanger },
            ]
        },
        {
            mainHeading: "Data Integration and Knowledge Management",
            subheading1: "Chronox AI seamlessly integrates your Slack bot with key data sources, pulling relevant information into conversations.",
            subheading2: "It retrieves real-time performance metrics, project updates, or customer records, ensuring critical insights are always accessible.",
            subheading3: "This streamlines workflows, reduces tool switching, and enables your team to make quick, informed decisions during conversations.",
            leftSideimage: false,
            image: thirdImage,
            altText: 'Auto-generated message templates for your Tasks & Messaging',
            points: [
                { label: 'Automatic WhatsApp messages', img: messanger },
                { label: 'Brand-specific templates', img: messanger },
                { label: 'Personalized message templates', img: messanger },
            ]
        },

    ]
    const CTABannerData = {
        heading: "Lorem Ipsum has been the industry's standard dummy text",
        description: " It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        btnText: "Lorem Ipsum"
    }
    const [chatbotFaqs, setChatbotFaqs] = useState([
        {
            question: "What makes this AI agent different from other chatbots?",
            answer: "Our AI agent isn’t just about conversations—it drives engagement with image carousels, quick replies, and actionable CTAs. It personalizes interactions, offers seamless human handoffs when needed, and integrates effortlessly with your existing tools, ensuring a smooth, efficient customer experience.",
        },
        {
            question: "How fast can I set it up on my website?",
            answer: "In just a few minutes! With our no-code, no workflow platform, you simply write what you need, and your chatbot is ready to go live. No complicated workflows or technical expertise required."
        },
        {
            question: "Can I customize the chatbot’s design and behavior?",
            answer: "Absolutely! From tone and personality to the chatbot’s visual design, you have complete control to match your brand perfectly. Embed it anywhere on your site—on product pages, forms, or checkout—so your customers get help where they need it most.",
        },
        {
            question: "Does the AI agent work with my existing systems?",
            answer: "Yes! Our AI agent integrates seamlessly with CRMs, helpdesk software, and other tools, ensuring your data flows smoothly and your team stays in sync."
        },
        {
            question: "What if the AI agent can’t answer a customer’s query?",
            answer:
                "No problem! When the AI reaches its limits, it hands over the conversation to a human agent smoothly—ensuring a seamless experience with zero disruption to the customer."
        },
        {
            question: "How does the chatbot help with engagement and conversions?",
            answer:
                "The AI agent interacts visually and dynamically—using image carousels, quick replies, and CTAs—to guide customers through product discovery, answer questions instantly, and drive meaningful actions like purchases or bookings."
        },
    ])

    const [pageName, setPageName] = useState('Chatbot');


    return (
        <>
            <Grid container>
                <Navbar setFlagBanner={setFlagBanner} flagBanner={flagBanner} />
                <Grid item xs={12} mt={flagBanner ? 7 : 0}>
                    <Grid container>
                        <Grid item xs={12} className='slack-side-by-side-hero-section'>
                            <SideBySideSectionWithBtn content={heroSectionData} flagHeroSection />
                        </Grid>
                        <Grid item xs={9} className='slack-client-logo-section' >
                            {/* <Grid container alignItems={"center"} gap={3} direction={"column"}>
                                <Grid item sx={{ fontSize: "36px", fontWeight: "600" }}>Trusted by 30+ businesses</Grid>
                                <ClientLogoSection content={ClientLogos} />
                            </Grid> */}
                            <AiWhatsappScrollingSection />
                        </Grid>
                        <Grid item xs={12}>
                            <ChatbotWhatsappGridSection groupSectionData={groupSectionData} />
                        </Grid>
                        <Grid item xs={12}>
                            <ChatbotFAQs faqData={chatbotFaqs} />
                        </Grid>
                        <Grid item xs={12} className='slack-image-banner'>
                            {/* <GlobalCTABanner content={CTABannerData} flagImage image={'https://cdn.prod.website-files.com/622611a2b23d0e6b270fba81/63ed0c34b1d4bf07890932ca_all-informations-in-slack_hu7253b0cff3ae198a75e8e7a709bb0101_34460_1336x0_resize_lanczos_3.png'} /> */}
                            <AIChatbotSimplified />
                        </Grid>
                        <Grid item xs={12} className='slack-pr-overlap-item'>
                            <ParaphraseOverlapFooter pageName={pageName} />
                        </Grid>

                        <Grid item xs={12} className='brsw-footer-component-main-item'>
                            <LandingFooter />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SlackAiChatbot
