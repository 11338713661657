import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import gitex from '../../assests/GlobalImages/gitex-frame.svg';
import './GitexAttendeesCard.css';
import tikit from '../../assests/GlobalImages/tickit.png';
import secure from '../../assests/GlobalImages/ticket-symbole.png';
import idea from '../../assests/Gitex/Idea.svg';
import speeches from '../../assests/Gitex/Speeches.svg';
import gallery from '../../assests/Gitex/Gellery.svg';

const GitexAttendeesCard = () => {

    const [count, setCount] = useState(0);
    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);

    const attendessCard = [
        {
            img: idea,
            head: `${count.toLocaleString()}+`,  // Dynamic value for attendees count
            subHead: 'Attendees',
            content: 'Uncover new opportunities with Chronox AI experts and partners'
        },
        {
            img: speeches,
            head: `${count1.toLocaleString()}+`,
            subHead: 'Speeches',
            content: 'Participate in the summit and dozens of sessions featuring more than a hundred speeches.'
        },
        {
            img: gallery,
            head: `${count2.toLocaleString()} m\u00B2`,
            subHead: 'Exhibition Space',
            content: 'Explore intelligent technology and its integration across diverse industries.'
        },
    ];

    const duration = 1000;
    const end = 200000;
    const steps = 20;

    const forSpeeches1 = 1000
    const forSpeeches2 = 100
    const forSpeeches3 = 20

    const forgallery1 = 1000
    const forgallery2 = 1000
    const forgallery3 = 20

    useEffect(() => {
        let currentStep = 0;
        const increments = [0]; // Start with an initial value of 0

        while (increments.length < steps) {
            const last = increments[increments.length - 1]; // Last number added
            // Generate the next random number and ensure it ends with '000'
            const nextRandom = Math.floor(Math.random() * ((end - last) / (steps - increments.length)) / 1000) * 1000 + last;
            increments.push(nextRandom);
        }
        increments[increments.length - 1] = end; // Ensure the last number is exactly 'end'

        const timer = setInterval(() => {
            setCount(increments[currentStep]);
            currentStep++;
            if (currentStep >= increments.length) {
                clearInterval(timer);
            }
        }, duration / steps);

        return () => clearInterval(timer);
    }, [end, duration, steps]);

    useEffect(() => {
        let currentStep = 0;
        const increments = [0]; // Start with an initial value of 0

        while (increments.length < forSpeeches3) {
            const last = increments[increments.length - 1]; // Last number added
            // Generate the next random number and ensure it ends with '000'
            const nextRandom = Math.floor(Math.random() * ((forSpeeches2 - last) / (forSpeeches3 - increments.length)) / 2) * 2 + last;
            increments.push(nextRandom);
        }
        increments[increments.length - 1] = forSpeeches2; // Ensure the last number is exactly 'forSpeeches2'

        const timer = setInterval(() => {
            setCount1(increments[currentStep]);
            currentStep++;
            if (currentStep >= increments.length) {
                clearInterval(timer);
            }
        }, forSpeeches1 / forSpeeches3);

        return () => clearInterval(timer);
    }, [forSpeeches2, forSpeeches1, forSpeeches3]);

    useEffect(() => {
        let currentStep = 0;
        const increments = [0]; // Start with an initial value of 0

        while (increments.length < forgallery3) {
            const last = increments[increments.length - 1]; // Last number added
            // Generate the next random number and ensure it ends with '000'
            const nextRandom = Math.floor(Math.random() * ((forgallery2 - last) / (forgallery3 - increments.length)) / 2) * 2 + last;
            increments.push(nextRandom);
        }
        increments[increments.length - 1] = forgallery2; // Ensure the last number is exactly 'forgallery2'

        const timer = setInterval(() => {
            setCount2(increments[currentStep]);
            currentStep++;
            if (currentStep >= increments.length) {
                clearInterval(timer);
            }
        }, forgallery1 / forgallery3);

        return () => clearInterval(timer);
    }, [forgallery2, forgallery1, forgallery3]);


    return (
        <>
            <Grid container>
                <Grid item xs={12} className='gac-main-item'>
                    <Grid container sx={{ alignItems: 'center', height: '100%', justifyContent: 'space-evenly' }}>
                        {attendessCard.map((ele, i) => (
                            <Grid item key={i} className='gac-section-item'>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <img src={ele.img} alt="" className='gac-card-img' />
                                    </Grid>
                                    <Grid item xs={12} sx={{ margin: '20px 0px 0px' }}>
                                        <Grid container sx={{ justifyContent: 'center' }}>
                                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                                <Typography variant='span' className='gac-card-head-text'>
                                                    {ele.head}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                                <Typography variant='span' className='gac-card-sub-head-text'>
                                                    {ele.subHead}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} mt={3} sx={{ textAlign: 'center' }}>
                                                <Typography variant='span' className='gac-card-sub-content-text'>
                                                    {ele.content}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default GitexAttendeesCard;
