import React from 'react';
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../Pages/OmnichannelDeployment/CarouselAnimation.css'

const AiChatbotMobileCarouselAnimation = () => {


    const smallScreen = useMediaQuery('(min-width: 0px) and (max-width: 1000px)')

    const mainData = [
        {
            name: "one",
            text: "",
            smallTitle: "",
            title: "Effortless Omnichannel Support",
            description: "AI chatbot offers frictionless omnichannel experiences, ensuring your brand is where your customers are. Elevate your customer service with unified conversations.",
        },
        {
            name: "two",
            text: "",
            smallTitle: "",
            title: "No-Code Platform - Empowering Every Team Member",
            description:
                "Harness the power of our user-friendly, no-code platform to create and customize your chatbot effortlessly.",
        },
        {
            name: "three",
            text: "",
            smallTitle: "",
            title: "Crafting Conversations That Reflect Your Brand",
            description:
                "Infuse your chatbot with a personality that mirrors your brand, making every interaction memorable and on-brand.",
        },
    ];


    // const mainData = [
    //     {
    //         name: "one",
    //         text: "Stay connected with your customers where they chat the most",
    //         smallTitle: "WhatsApp",
    //         title: "Fast and Secure Communication on WhatsApp",
    //         description: `Deliver instant notifications by providing seamless updates on order status, confirmations, and alerts. With an impressive 90% open rate, your messages are guaranteed to be read promptly. Enhance engagement through rich media support, allowing you to share images, videos, voice messages, and more. Stay connected, keep customers informed, and offer support—all directly through WhatsApp.`,
    //     },
    //     {
    //         name: "two",
    //         text: "Turn conversations into conversions by interacting with your customers on Instagram.",
    //         smallTitle: "Instagram",
    //         title: "Engage and Sell on Instagram Direct Messages",
    //         description:
    //             "Provide real-time support by offering instant responses and valuable product insights. Ensure a seamless shopping experience with product recommendations and in-app checkout options through Instagram’s messaging. Increase engagement with DM-based contests, polls, and automated replies to build stronger customer relationships. Connect with customers where they shop—right on Instagram.",
    //     },
    //     {
    //         name: "three",
    //         text: "Power Your Marketing with Precision",
    //         smallTitle: "Facebook Messenger",
    //         title: "Stay ahead of the competition by offering proactive, real-time support on Facebook",
    //         description: "Deliver 24/7 automated support by providing instant answers to FAQs and common inquiries. Boost conversions by showcasing products and guiding customers through purchases directly within the chat. Create personalized conversations that foster lasting customer relationships—all on Facebook.",
    //     },
    //     {
    //         name: "four",
    //         text: "Your website is the front door to your business",
    //         smallTitle: "Website",
    //         title: "Create an Impactful First Impression on Your Website",
    //         description:
    //             "Provide instant chat support to greet visitors and quickly address common questions. Seamlessly escalate conversations to live agents when needed, ensuring smooth transitions. Display integrated FAQs to resolve issues on the spot and enhance user experience. Make a lasting first impression and build trust—starting from your homepage.",
    //     },

    // ];

    return (
        <div className="usecasemobile-main-div" style={{ background: '#F7E9FF' }}>
            <Carousel
                autoPlay={true}
                className='CarouselStyles'
                infiniteLoop={true}
                autoFocus={true}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
                showStatus={false}

            >
                {mainData.map((data, index) => {
                    return (
                        <div className="usecasemobile-inner-div" key={index}>
                            <div className="usecasemobile-section">
                                <div
                                    className="usecasemobile-main-text"
                                >
                                    <div
                                        className="usecasemobile-main-text-inner-div"
                                    >
                                        <div className="usecasemobileSmallTitle" >
                                            {data.smallTitle}
                                        </div>

                                        <div className="carousel-main-title-text">{data.title}</div>

                                        {smallScreen ? (<></>) :
                                            (<>
                                                <div id="usecasemobileTitleDiv">
                                                    <span className="usecasemobileTitle">
                                                        {mainData[0].title?.split(" ").slice(0, -1).join(" ")}
                                                    </span>
                                                    <span className="usecasemobileTitle">
                                                        {mainData[0].title?.split(" ").splice(-1)[0]}
                                                    </span>
                                                </div>
                                            </>)
                                        }

                                        <div id="usecasemobileText">{data.text}</div>


                                        <div className="usecasemobileDescription">{data.description}</div>



                                    </div>
                                </div>
                                <div className="usecasemobile-main-image">
                                    <div className="usecasemobile-main-image-inner-div">
                                        <img
                                            src={data.image}
                                            style={{ width: "100%", }}
                                            alt=""
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                })}

            </Carousel>
        </div>
    )
}

export default AiChatbotMobileCarouselAnimation