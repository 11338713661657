import { Dialog, Grid } from '@mui/material'
import React, { useState } from 'react'
import './EcommerceComponent.css'
import Navbar from '../../../Pages/Navbar/Navbar'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CommonFormatImageContents from '../../CommonFormatImageContent/CommonFormatImageContents';
import demoImage1 from './../../../assests/LandingPage/commonSection1.svg'
import demoImage2 from './../../../assests/LandingPage/commonSection2.svg'
import demoImage3 from './../../../assests/LandingPage/commonSection3.svg'
import HeroSectionNew from '../../HeroSectionNew/HeroSectionNew';
import heroImage from './../../../assests/Industries/HealthCare/health-care-hero.svg'
import FooterComponent from '../HealthCareComponents/FooterComponent';
import ChannelCardsComponent from '../HealthCareComponents/ChannelCardsComponent';
import LandingFooter from '../../Footer/LandingFooter';
import ChatbotFAQs from '../../ChatbotFAQs/ChatbotFAQs';
import AiWhatsappScrollingSection from '../../AiWhatsappScrollingSection/AiWhatsappScrollingSection';
import AIChatbotSimplified from '../../AIChatbotSimplified/AIChatbotSimplified';
import ParaphraseOverlapFooter from '../../ParaphraserSections/ParaphraseOverlapFooter';
import BookAMeetingDialog from '../../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog';
import BookACalendlyDialog from '../../../Utils/GlobalDialog/BookACalendlyDialog/BookACalendlyDialog';

const EcommerceComponent = (props) => {
    const { flagBanner, setFlagBanner } = props;
    const [pageName, setPageName] = useState('Chatbot');
    const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);

    const [chatbotFaqs, setChatbotFaqs] = useState([
        {
            question: "How does a WhatsApp chatbot work?",
            answer: "A WhatsApp Chatbot uses AI and predefined responses to interact with users, answer queries, and perform tasks, providing a seamless experience.",
        },
        {
            question: "What are the benefits of using WhatsApp chatbots?",
            answer: "WhatsApp Chatbots improve efficiency, offer 24/7 support, and personalize interactions, leading to enhanced customer satisfaction and business growth."
        },
        {
            question: "Are WhatsApp chatbots secure?",
            answer: "WhatsApp Chatbots prioritize security, ensuring data protection and compliance with WhatsApp's policies.",
        },
        {
            question: "What can I ask the chatbot?",
            answer: "You can ask questions related to our services, product details, pricing, account management, support queries, and more. Our chatbot is designed to provide you with quick information and assist you in navigating our website."
        },
        {
            question: "How does the chatbot understand my questions?",
            answer:
                "Our chatbot uses advanced AI algorithms to understand natural language, which means you can ask questions just as you would to a human."
        },
    ])

    const heroContentData = {
        contentID: 1,
        contentHeading1: "Grow Faster, Sell Smarter with AI:",
        contentHeading2: " Automated Customer Care and Personalized Shopping Experiences",
        contentSubHeading: "Elevate your e-commerce platform with AI that offers 24/7 customer support, personalized recommendations, and smooth order management. Let our chatbot handle the details while you focus on growing your business.",
        buttonData: [
            {
                buttonStyle: "dark",
                buttonText: "Get Started",
                buttonlink: process.env.REACT_APP_REDIRECT_URL,
            },
            {
                buttonStyle: "light",
                buttonText: "Book a Demo",
                buttonlink: () => setFlagOpenBookaMeetingDemo(true)
            }
        ],
        listIcon: <CheckCircleOutlineIcon sx={{ fontSize: "20px" }} />,
        flagListData: false,
        // listData: [
        //     "Lorem ipsum dolor sit amet consectetur. Ornare varius risus erat mi.",
        //     "Lorem ipsum dolor sit amet consectetur. Ornare varius risus erat mi.",
        //     "Lorem ipsum dolor sit amet consectetur. Ornare varius risus erat mi."
        // ],
        flagFloatImage: false,
        contentImageSection: [
            {
                imgID: 1,
                imgName: "Image 1",
                imgLink: heroImage,
            },
        ]
    }

    const eCommerceData = [
        {
            healthCareID: 1,
            heading: "Anytime Customer Support",
            subHeading: "Assistance Available Around the Clock",
            subDataIcon: <CheckCircleOutlineIcon sx={{ display: "flex", fontSize: "17px", verticalAlign: "middle" }} />,
            classificationData: "Ensure your online customers have access to help 24/7. Our AI chatbot delivers immediate support, enhancing customer satisfaction.",
            subData: [
                "Quickly resolve customer inquiries and concerns.",
                "Automate order tracking and shipping notifications.",
                "Easily transfer complex inquiries to human agents."
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1

            // healthCareID: 1,
            // heading: "Increase Online Sales & Elevate Customer Experiences",
            // subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px", verticalAlign: "middle"}}/>,
            // subData: [
            //     "Deliver flash sales, product announcements, and special discounts at the perfect moment, driving purchase decisions and keeping your customers informed.",
            //     "Help shoppers navigate your e-commerce store, discover exclusive online deals, and find top-selling products with ease.",
            //     "Connect with your customers via Facebook, Google Business Messages (GBM), Instagram, and WhatsApp to offer personalized product recommendations and real-time shopping assistance." 
            // ],
            // buttonData: "Explore Conversational Marketing for E-Commerce",
            // imgData: demoImage1
        },
        {
            healthCareID: 2,
            heading: "Streamlined Checkout Process",
            subHeading: "Simplifying Your Customers' Purchases",
            subDataIcon: <CheckCircleOutlineIcon sx={{ display: "flex", fontSize: "17px", verticalAlign: "middle" }} />,
            classificationData: "Enhance the checkout experience for your customers. Our AI ensures a smooth process, significantly reducing cart abandonment.",
            subData: [
                "AI assists with checkout inquiries and payment processing.",
                "Provide real-time updates to keep customers informed about their orders.",
                "Enable various payment methods for customer convenience."
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1

            // healthCareID: 2,
            // heading: "Enhance Order Management & Customer Communication",
            // subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px"}}/>,
            // subData: [
            //     "Enable customers to adjust their orders, change delivery addresses, or alter preferences with minimal effort, providing them full control over their shopping journey.",
            //     "Keep customers in the loop with automatic order confirmations, shipment updates, and personalized post-purchase follow-ups to boost retention and satisfaction.",
            //     "Allow customers to browse your online catalog, place orders, and track deliveries anytime through a user-friendly chatbot on your website or via messaging platforms." 
            // ],
            // buttonData: "Learn More About Proactive Messaging for E-Commerce",
            // imgData: demoImage2
        },
        {
            healthCareID: 3,
            heading: "Tailored Shopping Assistance",
            subHeading: "Personalized Recommendations for Every Shopper",
            subDataIcon: <CheckCircleOutlineIcon sx={{ display: "flex", fontSize: "17px", verticalAlign: "middle" }} />,
            classificationData: "Improve customer experiences with personalized product suggestions. Our AI learns customer preferences to provide tailored options.",
            subData: [
                "Send customized product recommendations and promotions.",
                "Engage with customers via chat for enhanced product discovery.",
                "Transform website visitors into buyers with live assistance."
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1

            // healthCareID: 3,
            // heading: "Deliver 24/7 Customer Support to Drive Conversions",
            // subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px"}}/>,
            // subData: [
            //     "Deliver personalized product suggestions and promotional offers that meet customer needs at key moments, helping you secure more conversions and repeat business.",
            //     "Assist shoppers in navigating your store and finding the items they want through intuitive chatbot interactions, improving the overall shopping experience.",
            //     "Provide quick and effective customer service through platforms like Facebook, GBM, Instagram, and WhatsApp, helping shoppers with inquiries or product recommendations." 
            // ],
            // buttonData: "Learn More About E-Commerce Customer Care",
            // imgData: demoImage3
        },
        {
            healthCareID: 4,
            heading: "Increase Sales and Customer Loyalty",
            subHeading: "Automate Your Marketing Campaigns",
            subDataIcon: <CheckCircleOutlineIcon sx={{ display: "flex", fontSize: "17px", verticalAlign: "middle" }} />,
            classificationData: "Drive sales and enhance customer retention with automated marketing campaigns that reach the right audience.",
            subData: [
                "Send personalized notifications about promotions.",
                "Engage potential customers on various platforms (email, SMS, social media).",
                "Turn leads into loyal customers through consistent engagement."
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1
        }
    ]

    return (
        <Grid container>
            {
                flagOpenBookaMeetingDemo &&
                <Dialog open={flagOpenBookaMeetingDemo}
                    maxWidth='lg'
                    sx={{ '&.MuiPaper-root': { width: '100%' } }}
                    className="iframe-dialog"
                >
                    {/* <BookAMeetingDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    /> */}
                    <BookACalendlyDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    />
                </Dialog>
            }
            <Navbar flagBanner={flagBanner} setFlagBanner={setFlagBanner} />

            <Grid item xs={12} mt={{ xs: flagBanner ? 10 : 0, md: flagBanner ? 8 : 0 }} sx={{ overflowX: "hidden" }}>
                <Grid container>
                    <Grid item xs={12} className='healthcare-new-hero-section'>
                        <HeroSectionNew content={heroContentData} />
                    </Grid>

                    <Grid item xs={9} className='brsw-new-expertise-section-main-item'>
                        <AiWhatsappScrollingSection />
                    </Grid>

                    {
                        eCommerceData.map((data) =>
                            <Grid item xs={12} className='common-format-new-head-section-main-item'>
                                <CommonFormatImageContents content={data} />
                            </Grid>
                        )
                    }

                    {/* <Grid item xs={12} className='common-format-new-card-section'>
                        <ChannelCardsComponent />
                    </Grid> */}

                    {/* <Grid item xs={12} className='common-format-new-footer-section'>
                        <FooterComponent />
                    </Grid> */}

                    <Grid item xs={12} className='chatbot-faq-section'>
                        <ChatbotFAQs faqData={chatbotFaqs} />
                    </Grid>

                    <Grid item xs={12} className='brsw-new-aisimplified-section-main-item'>
                        <AIChatbotSimplified />
                    </Grid>

                    <Grid item xs={12} className='pr-overlap-item'>
                        <ParaphraseOverlapFooter pageName={pageName} />
                    </Grid>

                    <Grid item xs={12} className='brsw-footer-component-main-item'>
                        <LandingFooter />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EcommerceComponent
