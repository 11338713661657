import React from 'react'
// import "./LandingFooter.css";
import { Button, Grid, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import livechat from '../../assests/Paraphraser-Message.png'
import message from '../../assests/Paraphraser-Email.png'
import call from '../../assests/Paraphraser-call.png'
import whatsapp from '../../assests/Paraphraser-whatsapp.png'
import '../../Pages/Paraphraser/Paraphraser.css'
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import chronoxLogo from './../../assests/GlobalImages/Chronox-AI-Icon.svg'

const ParaphraseOverlapFooter = (props) => {

    console.log("props", props)

    const { pageName } = props

    const mobileScreen = useMediaQuery('(min-width : 0px) and (max-width : 600px)')

    function openGmail() {
        const email = "krishna@chronox.ai";
        const subject = encodeURIComponent("Feedback form for chronox Website");
        const body = encodeURIComponent("body");
        const gmailURL = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`;
        window.open(gmailURL, "_blank");
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} className={pageName == 'Chatbot' ? 'pr-ai-chatbot-item' : "pr-ai-footer-main-item"}>
                    <Grid container className={pageName == 'Chatbot' ? ' ' : "pr-ai-footer-main-container"}>

                        <Grid item xl={8} lg={8} md={10} sm={11} xs={11} className="pr-ai-white-overlap-item">
                            <Grid container className="lp-ft-white-overlap-container">
                                <Grid item xl={8} lg={8} md={9} sm={8} xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} className="lp-ft-overlap-text-main-item">
                                            <Grid container>
                                                <Grid item xs={12} className="pr-overlap-head-item" sx={{ margin: "5px 0px" }}>
                                                    <span>Ask our Team</span>
                                                </Grid>
                                                <Grid item xs={12} className="pr-overlap-sub-item" sx={{ margin: "5px 0px" }}>
                                                    <span>Want to connect us directly? No problem. We are always here for you.</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: { xs: "-10px 0px", sm: "10px 0px" } }}>
                                            <Grid container sx={{ alignItems: "center", gap: { xl: "20px", md: "20px", lg: "20px", sm: "10px", xs: "5px" } }}>

                                                <Grid item className='pr-contact-label-main-item' sx={{ cursor: 'pointer' }}>
                                                    <Grid container sx={{ alignItems: "center", gap: "10px" }}>
                                                        <Grid item onClick={() => { window.open('https://api.whatsapp.com/send?phone=917861834273', "_blank") }}>
                                                            <Grid container>
                                                                <img src={livechat} className='pr-contact-icons' alt="" />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item className='pr-ai-contact-label-item' onClick={() => { window.open('https://api.whatsapp.com/send?phone=917861834273', "_blank") }}>
                                                            <span>Start a live chat</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item className='pr-contact-label-main-item' sx={{ cursor: 'pointer' }}>
                                                    <Grid container sx={{ alignItems: "center", gap: "10px" }}>
                                                        <Grid item onClick={() => { window.open('https://api.whatsapp.com/send?phone=917861834273', "_blank") }}>
                                                            <Grid container>
                                                                <img src={message} className='pr-contact-icons' alt="" />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item className='pr-ai-contact-label-item'
                                                            // onClick={() => { window.open('https://api.whatsapp.com/send?phone=917861834273', "_blank") }}
                                                            onClick={() => { openGmail() }}
                                                        >
                                                            <span>krishna@chronox.ai</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item className='pr-contact-label-main-item' sx={{ cursor: 'pointer' }}>
                                                    <Grid container sx={{ alignItems: "center", gap: "10px" }}>
                                                        <Grid item onClick={() => { window.open('https://api.whatsapp.com/send?phone=917861834273', "_blank") }}>
                                                            <Grid container>
                                                                <img src={call} className='pr-contact-icons' alt="" />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item className='pr-ai-contact-label-item' onClick={() => { window.open('https://api.whatsapp.com/send?phone=917861834273', "_blank") }}>
                                                            <span>+91 7861834273</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {mobileScreen ? (<></>) : (
                                    <Grid item xl={3} lg={2.5} md={2.5} sm={3} xs={3}>
                                        <Grid container sx={{ justifyContent: 'center' }}>
                                            <AvatarGroup max={2} sx={{ flexDirection: "row" }} >
                                                {/* <img alt="Chrnox AI" src={chronoxLogo} className='pr-ai-avtar' style={{ marginRight: "-10px" }} /> */}
                                                <img alt="Krishna Desai" src="https://storage.writewiz-staging.in/general/Profile_Krishna_1702538220929.jpg" className='pr-ai-avtar' style={{ marginRight: "-10px" }} />
                                            </AvatarGroup>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ParaphraseOverlapFooter