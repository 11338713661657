import React, { useEffect, useRef, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Grid, Divider, Typography, MenuItem, Select } from '@mui/material';
import Navbar from '../Navbar/Navbar';
import TopPanel from '../../Component/TopPanel/TopPanel.jsx';
import './ArticlePage.css';
import WhatIsChatbot from './ArticlePageComponents/AIChatbot/AIChatbotComponents/WhatIsChatbot.jsx';
import WhatIsConversationalChatbot from './ArticlePageComponents/AIChatbot/AIChatbotComponents/WhatIsConversationalChatbot.jsx';
import ChatbotVsConversationalAI from './ArticlePageComponents/AIChatbot/AIChatbotComponents/ChatbotVsConversationalAI.jsx';
import UseCases from './ArticlePageComponents/AIChatbot/AIChatbotComponents/UseCases.jsx';
import CAIChatbotUseCases from './ArticlePageComponents/AIChatbot/AIChatbotComponents/CAIChatbotUseCases.jsx';
import FutureChatbot from './ArticlePageComponents/AIChatbot/AIChatbotComponents/FutureChatbot.jsx';
import RuleBasedChatbot from './ArticlePageComponents/AIChatbot/AIChatbotComponents/RuleBasedChatbot.jsx';
import RuleBasedChatbotBusiness from './ArticlePageComponents/AIChatbot/AIChatbotComponents/RuleBasedChatbotBusiness.jsx';
import EvolutionOfChatbot from './ArticlePageComponents/AIChatbot/EvoluteOfChatbots/EvolutionOfChatbot.jsx';
import EarlyDaysRuleBasedChatbot from './ArticlePageComponents/AIChatbot/EvoluteOfChatbots/EarlyDaysRuleBasedChatbot.jsx';
import NextStep from './ArticlePageComponents/AIChatbot/EvoluteOfChatbots/NextStep.jsx';
import RiseOfConversationalAI from './ArticlePageComponents/AIChatbot/EvoluteOfChatbots/RiseOfConversationalAI.jsx';
import Innovations from './ArticlePageComponents/AIChatbot/EvoluteOfChatbots/Innovations.jsx';
import Top5UseCasesForConversational from './ArticlePageComponents/AIChatbot/Top5UseCases/Top5UseCasesForConversational.jsx';
import Healthcare from './ArticlePageComponents/AIChatbot/Top5UseCases/Healthcare.jsx';
import Ecommerce from './ArticlePageComponents/AIChatbot/Top5UseCases/ECommerce.jsx';
import Insurance from './ArticlePageComponents/AIChatbot/Top5UseCases/Insurance.jsx';
import FoodAndHospital from './ArticlePageComponents/AIChatbot/Top5UseCases/FoodAndHospital.jsx';
import TravelAndHospitality from './ArticlePageComponents/AIChatbot/Top5UseCases/TravelAndHospitality.jsx';

function debounce(fn, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

function ArticlePage(props) {
  const blogData = {
    'blog-ai-chatbot': [
      {
        id: 1,
        title: 'Distinctions Between Rule-Based Chatbots and Conversational AI',
        component: <WhatIsChatbot />,
      },
      {
        id: 2,
        title: 'What is a Rule-Based Chatbot?',
        component: <RuleBasedChatbot />,
      },
      {
        id: 3,
        title: 'What is a Conversational AI Chatbot?',
        component: <WhatIsConversationalChatbot />,
      },
      {
        id: 4,
        title: 'Difference between a rule-based chatbot and conversational AI:',
        component: <ChatbotVsConversationalAI />,
      },
      {
        id: 5,
        title: 'Use cases for chatbot vs. conversational AI in customer service?',
        component: <UseCases />,
      },
      {
        id: 6,
        title: 'The Future of Chatbots vs. Conversational AI',
        component: <FutureChatbot />,
      },
      {
        id: 7,
        title: 'Rule-Based Chatbots vs. Conversational AI: Which is Best for Your Business?',
        component: <RuleBasedChatbotBusiness />,
      },
    ],
    "top-5-usecases": [
      {
        id: 1,
        title: 'Top 5 Use Cases for Conversational AI Chatbots in Customer Service',
        component: <Top5UseCasesForConversational />,
      },
      {
        id: 2,
        title: 'Healthcare: Appointment Scheduling and Patient Support',
        component: <Healthcare />,
      },
      {
        id: 3,
        title: 'E-Commerce: Personalized Shopping Assistance',
        component: <Ecommerce />,
      },
      {
        id: 4,
        title: 'Insurance: Generating Quotes and Handling Inquiries',
        component: <Insurance />,
      },
      {
        id: 5,
        title: 'Food and Hospitality: Reservation Management and Order Processing',
        component: <FoodAndHospital />,
      },
      {
        id: 6,
        title: 'Travel and Hospitality: Booking Assistance and Real-Time Updates',
        component: <TravelAndHospitality />,
      },
    ],
    'evolution-of-chatbots': [
      {
        id: 1,
        title: 'The Evolution of Chatbots: From Simple Automation to Intelligent Conversations',
        component: <EvolutionOfChatbot />,
      },
      {
        id: 2,
        title: 'Early Days: Rule-Based Chatbots',
        component: <EarlyDaysRuleBasedChatbot />,
      },
      {
        id: 3,
        title: 'Next Step: NLP-Powered Chatbots',
        component: <NextStep />,
      },
      {
        id: 4,
        title: 'The Rise of Conversational AI',
        component: <RiseOfConversationalAI />,
      },
      {
        id: 5,
        title: 'Key Innovations in Chatbot Technology',
        component: <Innovations />,
      },
    ],
    'chatbot-and-digital-transformation': [
      {
        id: 1,
        title: 'The Evolution of Chatbots: From Simple Automation to Intelligent Conversations',
        component: <EvolutionOfChatbot />,
      },
      {
        id: 2,
        title: 'Early Days: Rule-Based Chatbots',
        component: <EarlyDaysRuleBasedChatbot />,
      },
      {
        id: 3,
        title: 'Next Step: NLP-Powered Chatbots',
        component: <NextStep />,
      },
      {
        id: 4,
        title: 'The Rise of Conversational AI',
        component: <RiseOfConversationalAI />,
      },
      {
        id: 5,
        title: 'Key Innovations in Chatbot Technology',
        component: <Innovations />,
      },
    ],
  };

  const { blogName, blogHeader } = props;
  const [selectedItem, setSelectedItem] = useState(null);
  const sectionsRef = useRef([]);
  const currentBlogContent = blogData[blogName] || [];
  const [isMobile, setIsMobile] = useState(false);
  const [topPannelHeading, setTopPannelHeading] = useState(false);


  const checkIsMobile = () => {
    if (window.innerWidth <= 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    checkIsMobile(); // Run once when the component mounts
    window.addEventListener('resize', checkIsMobile); // Add event listener for window resizing
    return () => {
      window.removeEventListener('resize', checkIsMobile); // Cleanup on component unmount
    };
  }, []);

  useEffect(() => {
    if (currentBlogContent.length > 0 && !selectedItem) {
      setSelectedItem(currentBlogContent[0]);
    }
  }, [currentBlogContent, selectedItem]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentBlogContent, selectedItem]);

  const handleScroll = debounce(() => {
    let closestSection = null;
    let minDistance = Infinity;

    sectionsRef.current.forEach((section, index) => {
      if (section) {
        const rect = section.getBoundingClientRect();
        const distanceFromTop = Math.abs(rect.top);
        if (distanceFromTop < minDistance && rect.top >= 0) {
          minDistance = distanceFromTop;
          closestSection = currentBlogContent[index];
        }
      }
    });

    if (closestSection && closestSection.id !== selectedItem?.id) {
      setSelectedItem(closestSection);
    }
  }, 100);

  const handleListItemClick = (item) => {
    setSelectedItem(item);
    document.getElementById(item.id).scrollIntoView({ behavior: 'smooth' });
  };

  const handleDropdownChange = (event) => {
    const selectedSection = currentBlogContent.find((section) => section.id === event.target.value);
    if (selectedSection) {
      handleListItemClick(selectedSection);
    }
  };

  return (
    <>
      <Navbar />
      <Grid container sx={{ position: 'relative' }} className="arp-content-main-wrapper">
        <Grid item xs={12}>
          <TopPanel
            blogHeader={blogHeader}
          />
        </Grid>

        <Grid item xl={9} lg={11} md={11} sm={11} xs={11} className="arp-content-main-item">
          <Grid container className="arp-content-main-container">
            {isMobile ? (
              // Render Dropdown for Mobile
              <Grid item xs={12} className="arp-mobile-dropdown">
                <Select
                  value={selectedItem?.id || ''}
                  onChange={handleDropdownChange}
                  displayEmpty
                  size='small'
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select a section
                  </MenuItem>
                  {currentBlogContent.map((blogItem) => (
                    <MenuItem
                      key={blogItem.id}
                      value={blogItem.id}
                      sx={{ fontSize: '14px', fontWeight: '500', color: 'black' }}
                    >
                      {blogItem.title}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ) : (
              // Render Left-Side Navigation for Desktop
              <Grid item xl={2.5} lg={2.5} md={6} sm={2.5} xs={2.5} className="arp-nav-item">
                <List component="nav">
                  {currentBlogContent.map((blogItem) => (
                    <ListItem
                      key={blogItem.id}
                      onClick={() => handleListItemClick(blogItem)}
                      className={selectedItem?.id === blogItem.id ? 'arp-main-heading-selected' : 'arp-main-heading'}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: selectedItem?.id === blogItem.id ? '#f0f0f0' : 'transparent',
                      }}
                    >
                      <ListItemText className="arp-list-text" primary={blogItem.title} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}

            {/* Divider */}
            <Grid item className="arp-divider-item">
              <Divider orientation="vertical" flexItem className="arp-divider" />
            </Grid>

            {/* Right side: Content section (dynamically rendered component) */}
            <Grid item xl={9} lg={9} md={12} sm={11.5} xs={11.5} className="arp-blog-content-item">
              {currentBlogContent.map((blogItem, index) => (
                <div
                  id={blogItem.id}
                  key={blogItem.id}
                  ref={(el) => (sectionsRef.current[index] = el)}
                  className="arp-blog-head-wrapper"
                >
                  <Grid container>
                    <Grid item xs={11.5} className="arp-blog-head-title-item">
                      <Typography variant="h4" className="arp-blog-head-title">
                        {blogItem.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={11.5} sx={{ margin: '0 auto' }}>
                      {blogItem.component}
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ArticlePage;
