import React, { useState } from 'react'
import './GlobalTab.css'
import { Grid, Typography, Tabs, Tab, Box, Button, useMediaQuery } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GlobalTab = (props) => {

    const { tabData } = props
    const isBelowMobile = useMediaQuery('(min-width: 0px) and (max-width: 600px)')
    const [selectedTab, setSelectedTab] = useState(0);



    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} className='gb-tab-main-item'>
                    <Grid container>
                        {!isBelowMobile ?
                            <>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {tabData?.mainHead &&
                                            <Grid item xs={12} className="gb-tab-header">
                                                <Typography variant="h4" className="gb-tab-main-heading">
                                                    {tabData?.mainHead}
                                                </Typography>
                                            </Grid>
                                        }

                                        {tabData?.tabButton &&
                                            <Grid item xs={12} sx={{ margin: '30px 0px 0px' }}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Grid container justifyContent="center" spacing={2}>
                                                            {tabData.tabButton.map((tab, index) => (
                                                                <Grid item key={index}>
                                                                    <Button
                                                                        className={`gb-tab-button ${selectedTab == index ? 'gb-tab-button-active' : ''}`}
                                                                        startIcon={tab.icon}
                                                                        onClick={() => setSelectedTab(index)}
                                                                    >
                                                                        {tab.headText}
                                                                    </Button>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Grid>

                                                    {/* Tab Content */}
                                                    <Grid item xs={12} className="gb-tab-content">
                                                        <Grid container sx={{ justifyContent: 'space-between' }}>
                                                            <Grid item xs={5.5} className='gb-tab-content-item'>
                                                                <Grid container sx={{ justifyContent: 'space-between', gap: "15px", alignItems: 'center' }}>
                                                                    <Grid item xs={12} className='gb-tab-head-text-item'>
                                                                        <span >
                                                                            {tabData.tabButton[selectedTab].headText}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={12} className='gb-tab-subhead-text-item'>
                                                                        <span>
                                                                            {tabData.tabButton[selectedTab].subText}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={12} className='gb-tab-caption-text-item'>
                                                                        <span>
                                                                            {tabData.tabButton[selectedTab].caption}
                                                                        </span>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {tabData.tabButton[selectedTab].img &&
                                                                <Grid item xs={5.5}>
                                                                    <img src={tabData?.tabButton[selectedTab]?.img} alt="" srcset="" className={selectedTab == 0 ? 'table-data-img-first' : "table-data-img"} />
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ margin: '0 auto' }}>
                                    <Slider
                                        dots={true}
                                        infinite={true}
                                        autoplay={false}
                                        className='chatbot-click-slider'
                                        speed={400}
                                        arrow={false}
                                        arrows={false}
                                        slidesToShow={1}
                                        slidesToScroll={1}
                                        appendDots={dots => (
                                            <div className="dot-container">
                                                <ul className="chatbot-dots">{dots}</ul>
                                            </div>
                                        )}
                                    >
                                        {tabData?.tabButton?.map((data) => {
                                            return (
                                                <Grid container>
                                                    <Grid item xs={12} className='mb-tab-bg'>
                                                        <Grid container>
                                                            <Grid item xs={12} className='mb-tab-head-text'>
                                                                <Typography variant='span'>
                                                                    {data.headText}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <img src={data?.img} style={{ width: '100%', height: '500px', maxHeight: '500px', objectFit: 'contain' }} alt="" srcset="" />
                                                            </Grid>
                                                            <Grid item xs={12} sx={{ margin: '20px 0px 0px' }}>
                                                                <Grid container>

                                                                    <Grid item xs={12} className='mb-tab-subhead-text'>
                                                                        <Typography variant='span'>
                                                                            {data.subText}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} className='mb-tab-caption-text'>
                                                                        <Typography variant='span'>
                                                                            {data.caption}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Slider>
                                </Grid>
                            </>}

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default GlobalTab

