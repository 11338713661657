import { colors, Grid, Typography } from '@mui/material'
import React from 'react'
import './AIFacebookEngagement.css'



const AIFacebookEngagement = (props) => {

    const { data } = props

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xl={9} lg={11} sm={11} md={11} xs={11} className='ai-fbe-main-item'>
                            <Grid container>
                                <Grid item xl={7} lg={7.5} md={7.5} sm={12} xs={12} className='ai-fb-head-item'>
                                    <Typography variant='span' className='ai-fb-main-head'>
                                        Are you on Instagram?
                                        Because your customers surely are!
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='span' className='ai-fb-sub-head'>
                                        Instagram in numbers
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className='ai-fbe-card-item'>
                                    <Grid container sx={{ justifyContent: 'center', gap: "15px" }}>
                                        {data.map((ele) => {
                                            return (
                                                <>
                                                    <Grid item xs={3}
                                                        sx={{ background: ele.bgColor }}
                                                        className='ai-fbe-card-sub-item'
                                                    >
                                                        <Grid container sx={{ height: '100%', alignItems: 'center' }}>
                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    <Grid item xs={12} sx={{ marginBottom: '5px' }}>
                                                                        <Typography
                                                                            variant='span'
                                                                            className='ai-fbe-card-head-text'
                                                                            sx={{ color: ele.color }}
                                                                        >
                                                                            {ele.head}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} sx={{ marginTop: '5px' }}>
                                                                        <Typography
                                                                            variant='span'
                                                                            className='ai-fbe-card-subhead-text'
                                                                            sx={{ color: ele.color }}
                                                                        >
                                                                            {ele.subHead}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )
                                        })}

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default AIFacebookEngagement