import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import { Dialog, Grid } from '@mui/material'
import SideBySideSectionWithBtn from '../../Component/GlobalComponents/SideBySideSectionWithBtn/SideBySideSectionWithBtn'
import './Omnichannel.css'
import CustomCarousel from '../../Component/GlobalComponents/CustomCarousel/CustomCarousel'
import CommonFormatGridStructure from '../../Component/CommonFormatGridStructure/CommonFormatGridStructure'
import gridIcon from '../../assests/Linkdin.svg'
import GlobalCTABanner from '../../Component/GlobalComponents/GlobalCTABanner/GlobalCTABanner'
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileCarouselAnimation from './CarouselAnimationMobile'
import CarouselAnimaion from './CarouselAnimation'
import FooterComponent from '../../Component/IndustrialComponents/HealthCareComponents/FooterComponent'
import LandingFooter from '../../Component/Footer/LandingFooter'
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs'
import BookAMeetingDialog from '../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog'
import AIChatbotSimplified from '../../Component/AIChatbotSimplified/AIChatbotSimplified'
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter'
import omini from '../../assests/Platform/Omini/Omnichannel.png'
import BookACalendlyDialog from '../../Utils/GlobalDialog/BookACalendlyDialog/BookACalendlyDialog'

const Omnichannel = (props) => {

    const { setFlagBanner, flagBanner } = props
    const [pageName, setPageName] = useState('Chatbot');
    const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);
    const isBelow900 = useMediaQuery('(min-width: 0px) and (max-width: 900px)')

    const heroSectionData = {
        heading: "Be Everywhere Your Customers Are: Unlock Seamless Support with Omnichannel AI Deployment",
        description: "Today’s customers want fast, convenient help—no matter where they are. With Omnichannel Deployment, the AI chatbot can connect with users across every digital channel, ensuring that the support is never out of reach. Whether they're on your website, their favorite messaging app, or social media, you’ll be ready to respond instantly.",
        image: omini,
        btnText: "Book a demo",
        flagImageDirection: isBelow900 ? "right" : "left",
        textDirection: "left",
        btnGlobalCustomStyle: 'omini-gbl-contained-btn-style',
        btnVariant: 'contained',
        onClick: () => setFlagOpenBookaMeetingDemo(true),
    }
    const componentData = {
        heading: "Manage all your messaging channels with one API",
        description: "Our omnichannel approach allows your AI chatbot to be deployed across multiple platforms from a single, centralized hub. This means you can manage interactions on your website, Facebook, Instagram, WhatsApp, and more, all from one place. No matter how many channels you use, our chatbot ensures a smooth and cohesive experience for your users.",
        image: "https://img.freepik.com/free-photo/close-up-person-working-alternative-energy_23-2149311499.jpg",
        btnText: "Conversation API",
        flagImageDirection: "left",
        textDirection: "left",
        btnGlobalCustomStyle: 'omini-gbl-contained-btn-style',
        btnVariant: 'contained',
        onClick: () => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_self')
    }
    const gridStructureData = [
        {
            gridID: 1,
            gridIcon: gridIcon,
            gridHeading: "Protect patient information",
            gridContent: "Protect patient information with 2 Factor Authentication (2FA) to confirm patient identity"
        },
        {
            gridID: 2,
            gridIcon: gridIcon,
            gridHeading: "Protect patient information",
            gridContent: "Protect patient information with 2 Factor Authentication (2FA) to confirm patient identity"
        },
        {
            gridID: 3,
            gridIcon: gridIcon,
            gridHeading: "Protect patient information",
            gridContent: "Protect patient information with 2 Factor Authentication (2FA) to confirm patient identity"
        },
        {
            gridID: 4,
            gridIcon: gridIcon,
            gridHeading: "Protect patient information",
            gridContent: "Protect patient information with 2 Factor Authentication (2FA) to confirm patient identity"
        }
    ]

    const CTABannerData = {
        heading: "Lorem Ipsum has been the industry's standard dummy text",
        description: " It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        btnText: "Lorem Ipsum"
    }

    const [chatbotFaqs, setChatbotFaqs] = useState([
        {
            question: "What makes this AI agent different from other chatbots?",
            answer: "Our AI agent isn’t just about conversations—it drives engagement with image carousels, quick replies, and actionable CTAs. It personalizes interactions, offers seamless human handoffs when needed, and integrates effortlessly with your existing tools, ensuring a smooth, efficient customer experience.",
        },
        {
            question: "How fast can I set it up on my website?",
            answer: "In just a few minutes! With our no-code, no workflow platform, you simply write what you need, and your chatbot is ready to go live. No complicated workflows or technical expertise required."
        },
        {
            question: "Can I customize the chatbot’s design and behavior?",
            answer: "Absolutely! From tone and personality to the chatbot’s visual design, you have complete control to match your brand perfectly. Embed it anywhere on your site—on product pages, forms, or checkout—so your customers get help where they need it most.",
        },
        {
            question: "Does the AI agent work with my existing systems?",
            answer: "Yes! Our AI agent integrates seamlessly with CRMs, helpdesk software, and other tools, ensuring your data flows smoothly and your team stays in sync."
        },
        {
            question: "What if the AI agent can’t answer a customer’s query?",
            answer:
                "No problem! When the AI reaches its limits, it hands over the conversation to a human agent smoothly—ensuring a seamless experience with zero disruption to the customer."
        },
        {
            question: "How does the chatbot help with engagement and conversions?",
            answer:
                "The AI agent interacts visually and dynamically—using image carousels, quick replies, and CTAs—to guide customers through product discovery, answer questions instantly, and drive meaningful actions like purchases or bookings."
        },
    ])

    const isBelow1000 = useMediaQuery('(min-width: 0px) and (max-width: 1000px)')

    const carouselAnimationData = [
        {
            name: "one",
            text: "Stay connected with your customers where they chat the most",
            smallTitle: "WhatsApp",
            title: "Fast and Secure Communication on WhatsApp",
            description: `Deliver instant notifications by providing seamless updates on order status, confirmations, and alerts. With an impressive 90% open rate, your messages are guaranteed to be read promptly. Enhance engagement through rich media support, allowing you to share images, videos, voice messages, and more. Stay connected, keep customers informed, and offer support—all directly through WhatsApp.`,
        },
        {
            name: "two",
            text: "Turn conversations into conversions by interacting with your customers on Instagram.",
            smallTitle: "Instagram",
            title: "Engage and Sell on Instagram Direct Messages",
            description:
                "Provide real-time support by offering instant responses and valuable product insights. Ensure a seamless shopping experience with product recommendations and in-app checkout options through Instagram’s messaging. Increase engagement with DM-based contests, polls, and automated replies to build stronger customer relationships. Connect with customers where they shop—right on Instagram.",
        },
        {
            name: "three",
            text: "Power Your Marketing with Precision",
            smallTitle: "Facebook Messenger",
            title: "Stay ahead of the competition by offering proactive, real-time support on Facebook",
            description: "Deliver 24/7 automated support by providing instant answers to FAQs and common inquiries. Boost conversions by showcasing products and guiding customers through purchases directly within the chat. Create personalized conversations that foster lasting customer relationships—all on Facebook.",
        },
        {
            name: "four",
            text: "Your website is the front door to your business",
            smallTitle: "Website",
            title: "Create an Impactful First Impression on Your Website",
            description:
                "Provide instant chat support to greet visitors and quickly address common questions. Seamlessly escalate conversations to live agents when needed, ensuring smooth transitions. Display integrated FAQs to resolve issues on the spot and enhance user experience. Make a lasting first impression and build trust—starting from your homepage.",
        },

    ];

    return (
        <>

            {
                flagOpenBookaMeetingDemo &&
                <Dialog open={flagOpenBookaMeetingDemo}
                    maxWidth='lg'
                    sx={{ '&.MuiPaper-root': { width: '100%' } }}
                    className="iframe-dialog"
                >
                    {/* <BookAMeetingDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    /> */}
                    <BookACalendlyDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    />
                </Dialog>
            }
            <Grid container>
                <Navbar setFlagBanner={setFlagBanner} flagBanner={flagBanner} />
                <Grid xs={12} item mt={flagBanner ? 8 : 0}>
                    <Grid container>
                        <Grid item className={'omni-side-by-side-hero-section'} >
                            <SideBySideSectionWithBtn content={heroSectionData} flagHeroSection flagLeftSideImage />
                        </Grid>
                        <Grid item className='omni-carousel-animation-section'>
                            {isBelow1000 ? <MobileCarouselAnimation mainData={carouselAnimationData} /> : <CarouselAnimaion mainData={carouselAnimationData} />}
                        </Grid>
                        <Grid item className={'omni-side-by-side-section'} >
                            <SideBySideSectionWithBtn flagLeftSideImage content={componentData} />
                        </Grid>
                        {/* <Grid item xs={12} className={'omni-cardComponent'} >
                            <Grid container justifyContent={"center"}>
                                <Grid item sx={{ fontWeight: "600", fontSize: "30px", marginTop: '70px', width: "auto", maxWidth: "1000px", textAlign: "center" }}>Build a world-class mobile customer experience</Grid>
                                <Grid item >
                                    <CommonFormatGridStructure content={gridStructureData} flagNoBorder />
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12} sx={{ margin: '100px 0px 50px' }}>
                            <ChatbotFAQs faqData={chatbotFaqs} />
                        </Grid>

                        <Grid item xs={12} sx={{ margin: '50px 0px 200px' }}>
                            <AIChatbotSimplified />
                        </Grid>

                        <Grid item xs={12} className='pr-overlap-item'>
                            <ParaphraseOverlapFooter pageName={pageName} />
                        </Grid>

                        <Grid item xs={12} className='brsw-footer-component-main-item'>
                            <LandingFooter />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Omnichannel
