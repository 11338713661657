


import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import '../../../ArticlePage.css'
import CircleIcon from '@mui/icons-material/Circle';

const Top5UseCasesForConversational = () => {

    const useCaseData = [
        {
            mainHead: "Top 5 Use Cases for Conversational AI Chatbots in Customer Service:",
            subhead: "Conversational AI chatbots have revolutionized customer service across multiple industries. With their ability to understand context, engage in dynamic conversations, and handle more complex queries, they offer businesses a powerful tool to enhance customer experience and streamline operations. Here are the top 5 use cases for conversational AI chatbots in customer service, broken down by industry.",
            mainPoints: [
                {
                    title: 'Healthcare: Appointment Scheduling and Patient Support',
                    content: "In healthcare, conversational AI chatbots are a game-changer for handling patient appointments and providing real-time support. Unlike basic chatbots, AI-powered bots can engage in more natural conversations with patients, helping them not only schedule appointments but also manage follow-up queries and health reminders.",
                    ussagePoints: [
                        'Scheduling and confirming appointments',
                        'Providing real-time information on clinic hours, services, and insurance coverage',
                        'Answering questions about symptoms and offering basic medical advice'
                    ]
                },
                {
                    title: 'E-Commerce: Personalized Shopping Assistance.',
                    content: "TE-commerce businesses thrive on personalized customer experiences, and conversational AI chatbots are perfect for delivering this. By analyzing user preferences and browsing history, these bots can recommend products, answer questions about orders, and even handle complex returns.",
                    ussagePoints: [
                        'Assistance with order tracking, cancellations, or returns',
                        'Providing real-time answers to customer inquiries about pricing, discounts, and stock availability',
                    ]
                },
                {
                    title: 'Insurance: Generating Quotes and Handling Inquiries',
                    content: "In the insurance industry, conversational AI chatbots simplify the process of generating quotes and handling customer inquiries. These bots can guide users through the process of selecting the right insurance plan, answering questions about coverage options, and helping customers complete their applications.",
                    ussagePoints: [
                        'Generating personalized insurance quotes based on user inputs',
                        'Answering questions about policy coverage, premiums, and deductibles',
                        'Guiding customers through the process of applying for insurance or filing claims',
                    ]
                },
                {
                    title: 'Food and Hospitality: Reservation Management and Order Processing',
                    content: "In the food and hospitality industry, conversational AI chatbots make reservations, take orders, and answer questions seamlessly. Whether it’s booking a table at a restaurant or processing room service orders in hotels, these bots help streamline operations and ensure a smooth customer experience.",
                    ussagePoints: [
                        'Managing restaurant reservations or hotel bookings, guiding customers through available options',
                        'Answering questions about menus, available rooms, or services',
                        'Answering questions about destination requirements, like visa or health protocols',
                    ]
                },
            ]
        },
    ]





    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                Chatbots have come a long way from their early days of simple automation to becoming advanced, AI-powered conversational tools. What started as basic rule-based systems has evolved into sophisticated conversational AI that can understand, respond, and learn from interactions—revolutionizing how businesses engage with their customers. Let’s take a closer look at the journey of chatbots, exploring the key milestones and innovations that shaped their development.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* {useCaseData.map((ele,) => {
                return (
                    <>
                        <Grid item xs={12} sx={{ m: '10px 0px 30px' }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                {ele.subhead}
                            </Typography>
                        </Grid>
                        {ele.mainPoints.map((data, index) => {
                            const useCaseIdx = index + 1
                            return (
                                <>
                                    <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                                        <Grid container>
                                            <Grid item xs={12} className='arp-paragraph-global-text-item'>
                                                <Typography
                                                    component='span'
                                                    className='arp-paragraph-global-head-text'
                                                >
                                                    {useCaseIdx}. {data.title}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} className='arp-paragraph-global-head-text-item'>
                                                <Grid container>
                                                    <Grid item xs={12} sx={{ mb: '30px' }}>
                                                        <Typography
                                                            component='span'
                                                            className='artical-paragraph-global-text'
                                                        >
                                                            {data.content}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <Grid container>
                                                            <Grid item xs={12} className='arp-paragraph-global-text-item'>
                                                                <Typography
                                                                    variant='span'
                                                                    sx={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                                                                >
                                                                    Comman uses:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} className='arp-paragraph-global-text-item'>
                                                                {data?.ussagePoints?.map((points) => {
                                                                    return (
                                                                        <>
                                                                            <Grid container sx={{ marginLeft: '20px' }}>
                                                                                <Grid item xs={12}>
                                                                                    <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                        <Grid item sx={{ maxWidth: '15px' }}>
                                                                                            <CircleIcon style={{ width: '10px', height: 'auto' }} fontSize="small" />
                                                                                        </Grid>
                                                                                        <Grid item sx={{ maxWidth: "calc(100% - 20px)", width: "calc(100% - 15px)" }}>
                                                                                            <Typography
                                                                                                variant='span'
                                                                                                sx={{ fontSize: '14px', fontWeight: '500', color: 'black' }}
                                                                                            >
                                                                                                {points}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                        </>
                                                                    )
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })

                        }
                    </>
                )
            })} */}

        </>
    )
}

export default Top5UseCasesForConversational