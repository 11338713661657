import React from 'react';
import './ChatbotPricingHeadSection.css'
import { Grid } from '@mui/material';
import checkmark from '../../assests/ChatbotPricing/cb-checkmark.png'



const ChatbotPricingHeadSection = () => {
    return (
        <>
            <Grid container>
                <Grid item xs={12} className='cp-head-mian-item'>
                    <Grid container sx={{ padding: "80px 0 40px" }}>
                        <Grid item xl={5} lg={6} md={6} sm={6} xs={11} className='cp-head-text-item'>
                            <span className='cp-head-text'>
                                AI Agent Pricing
                            </span>
                        </Grid>
                        <Grid item xs={11} className='cp-subhead-text-item'>
                            <span className='cp-subhead-text'>
                                Your path to effortless engagement, retention, and building loyal customers
                            </span>
                        </Grid>
                        <Grid item xs={11} className='cp-subhead-text-item'>
                            <Grid container sx={{ justifyContent: { xs: 'start', sm: 'center' }, gap: '20px', alignItems: "center" }}>
                                {/* <Grid item className='cp-checkmarks-item'>
                                    <Grid container>
                                        <img src={checkmark} style={{ width: '20px', height: '20px', objectFit: 'fill' }} alt="" />&nbsp;
                                        <span className='cp-checmark-text'>No Credit Card required</span>
                                    </Grid>
                                </Grid> */}
                                <Grid item className='cp-checkmarks-item'>
                                    <Grid container>
                                        <img src={checkmark} style={{ width: '20px', height: '20px', objectFit: 'fill' }} alt="" />&nbsp;
                                        <span className='cp-checmark-text'>Feature Rich</span>&nbsp;
                                    </Grid>
                                </Grid>
                                <Grid item className='cp-checkmarks-item'>
                                    <Grid container>
                                        <img src={checkmark} style={{ width: '20px', height: '20px', objectFit: 'fill' }} alt="" />&nbsp;
                                        <span className='cp-checmark-text'>Competitive Pricing</span>&nbsp;
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChatbotPricingHeadSection