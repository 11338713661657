import { Grid, Typography, useMediaQuery } from "@mui/material";
import "./ChatbotCardItems.css"
import Usetool1 from '../../assests/Usetool1.png'
import Usetool2 from '../../assests/Usetool2.png'
import Usetool3 from '../../assests/Usetool3.png'
import Usetool4 from '../../assests/Usetool4.png'
import Usetool5 from '../../assests/Usetool5.png'
import Usetool6 from '../../assests/Usetool6.png'
import Usetool7 from '../../assests/Usetool7.svg'
import Usetool8 from '../../assests/Usetool8.png'
import dummycardImg from "../../assests/dummy.png"
import { useEffect, useRef } from "react";
let useThisTool = [
    {
        img: Usetool1,
        mainHead: "Retail, E-commerce",
        subHead: "Chatbots provide instant responses to customer inquiries, handle complaints, track orders, and offer product recommendations."
    },
    {
        img: Usetool2,
        mainHead: "Education, E-learning",
        subHead: "Chatbots deliver personalized learning experiences, offer tutoring, answer student queries in real-time, and provide educational resources."
    },
    {
        img: Usetool3,
        mainHead: "Travel, Hospitality",
        subHead: "Offer travel suggestions, book flights and accommodations, provide real-time updates on travel restrictions, and assist in travel planning experience."
    },
    {
        img: Usetool4,
        mainHead: "Banking, Finance",
        subHead: "Chatbots can simplify complex financial services, making them more user-friendly."
    },
    {
        img: Usetool5,
        mainHead: "Healthcare, Fitness",
        subHead: "Chatbots can motivate users, track progress, and provide customized health and wellness plans."
    },
    {
        img: Usetool6,
        mainHead: "Event Management",
        subHead: "Automate event registrations, provide information on schedules, speakers, and more with personalized event experiences."
    },
    {
        img: Usetool7,
        mainHead: "Human Resources",
        subHead: "Automate answering FAQs about job positions, improving efficiency in hiring."
    },
    {
        img: Usetool8,
        mainHead: "Wellness, Services",
        subHead: "Automate booking processes, send appointment reminders, and handle rescheduling or cancellations, streamlining operations."
    }
]

let useCase = [
    {
        title: "Customer Support",
        descrption: "Say goodbye to long waiting times. Our AI chatbots offer instant, accurate support, making every customer feel valued",
        image: "https://storage.writewiz-staging.in/general/customer_support_image_1710941415557.png"
    },
    {
        title: "Lead Generation",
        descrption: "Turn conversations into customers. Our chatbots qualify leads in real-time, driving sales and growth",
        image: "https://storage.writewiz-staging.in/general/lead_generation_image_1710941450183.png"
    }
]


const ChatbotCardItems = () => {
    const smallScreen = useMediaQuery('(min-width: 0px) and (max-width: 650px)')
    const servicesCardsSectionRef = useRef(null)

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                }
            });
        }, options);
        if (servicesCardsSectionRef.current) {
            observer.observe(servicesCardsSectionRef.current);
        }
        return () => {
            if (servicesCardsSectionRef.current) {
                observer.unobserve(servicesCardsSectionRef.current);
            }
        };
    }, [])
    return (
        <Grid container className="cci-main-container">
            <Grid item xs={12} className="cci-main-item-1" ref={servicesCardsSectionRef}>
                <Grid container>
                    <Grid item xs={11} sx={{ margin: "auto" }}>
                        <Grid container className="cci-items-container" >
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                <Grid container sx={{ textAlign: "center", justifyContent: "center" }}>
                                    <Typography variant="span" className="cci-main-heading">
                                        Custom AI Chatbots for Every Industry
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={11} className="cci-subheading-item">
                                <Typography className="cci-subheading-typo" variant="span" >
                                    Tailored Solutions That Understand Your Industry's Unique Needs
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="cci-main-cards-item" >

                                <Grid container className="cci-cards-container">
                                    {
                                        useThisTool.map((content, index) => (
                                            <Grid item className="cci-card-item" style={{ '--delay': index }}>
                                                <Grid container className="cci-cards-item-container">
                                                    {
                                                        smallScreen &&
                                                        <Grid item xs={1.3}>
                                                            <img src={content.img} alt={content.img} className="cci-cards-img" loading="lazy" />
                                                        </Grid>
                                                    }
                                                    <Grid item xs={smallScreen ? 10 : 12}>
                                                        <Grid container>
                                                            <Grid item>
                                                                <Typography variant="span" className="cci-cards-heading">
                                                                    {content.mainHead}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item className="cci-cards-content-item" >
                                                                <Typography variant='span' className="cci-cards-content" >
                                                                    {content.subHead}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            <Grid item xs={12} className="cci-main-item-2" >
                <Grid container>
                    <Grid item xl={9} lg={9} md={9} sm={10} xs={11} sx={{ margin: "auto" }}>
                        <Grid container sx={{ justifyContent: "center" }}>

                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                <Grid container sx={{ textAlign: "center", justifyContent: "center" }}>
                                    <Typography variant="span" className="cci-main-item-2-heading">
                                        Here are some of the ways you can use chatbase
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className="cci-subheading-item">
                                <Typography className="cci-subheading-typo" variant=" span" sx={{ fontSize: "20px", fontWeight: "500", color: "#1F384C" }}>
                                    Learn How Chronox AI Chatbot Transforms Challenges into Success Stories
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className="cci-chatbase-cards-item" >
                                <Grid container className="cci-item-2-cards-container">

                                    {
                                        useCase.map((data) => (
                                            <Grid item xl={5.6} lg={5.6} md={5.6} sm={12} xs={12}>
                                                <Grid container className="cci-item-2-card-container">
                                                    <Grid item xs={12}>
                                                        <Typography variant="span" className="cci-cards-heading">
                                                            {data.title}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ marginTop: "8px" }}>
                                                        <Typography variant="span" className="cci-cards-content">
                                                            {data.descrption}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ marginTop: "50px" }}>
                                                        <img src={data.image} className="cci-item-2cards-img" loading="lazy" />
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        ))
                                    }
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}
export default ChatbotCardItems;