import React from 'react'
import './InstagramImproveTeamCollabration.css'
import SideBySideSectionWithBtn from '../GlobalComponents/SideBySideSectionWithBtn/SideBySideSectionWithBtn'
import img1 from '../../assests/Solution/Instagram/instagram 5.png'
import EastIcon from '@mui/icons-material/East';

const InstagramImproveTeamCollabration = () => {

    const heroSectionData = {
        heading: "Multi-Channel AI Management",
        description: "Manage all your Instagram conversations and other platforms, such as WhatsApp or Messenger, from a unified interface. With Chronox AI’s multichannel tool, easily track, monitor, and switch between platforms for a seamless, omnichannel customer experience.",
        image: img1,
        btnText: "Try the Chronox multichannel tool",
        pointText: [
            'Streamline customer interactions across multiple platforms',
            'AI-powered conversation flows ensure consistent and efficient engagement',
            // 'Transfer your sales info to Zapier and manage your whole sales funnel with a helicopter view.',
        ],
        flagImageDirection: "right",
        btnGlobalCustomStyle: 'in-gcs-style',
        btnVariant: 'text',
        globalEndIcon: <EastIcon />,
        onClick: () => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')
    }

    return (
        <>
            <SideBySideSectionWithBtn content={heroSectionData} />
        </>
    )
}

export default InstagramImproveTeamCollabration